import { useLocation, useParams } from 'react-router-dom';
import { AssessmentLayout, Customer } from '__generated__/graphql';
import { LocationBar } from 'components';
import { EditNote } from '@mui/icons-material';
import { v4 as uuidv4 } from 'uuid';
import AssessmentStepper from '../components/AssessmentStepper';

const MedicationAssessmentForm = () => {
  const location = useLocation();
  const { id } = useParams();
  const { customer } = location.state as { customer: Customer };
  const assessmentId = id === 'new' ? uuidv4() : id;

  const steps: AssessmentLayout[] = [
    {
      type: 'section',
      label: 'Administration support',
      questions: [
        {
          required: false,
          hasNote: true,
          questionId: 'administration_support_level',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'friends_and_family_administration',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'needs_with_medication_support',
        },
      ],
    },
    {
      type: 'section',
      label: 'Types of medication',
      questions: [
        {
          required: false,
          hasNote: true,
          questionId: 'taking_oral_medication',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'on_insulin',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'peg_in_situ',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'taking_inhalers',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'blood_glucose_testing_required',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'has_recipient_suffered_side_effects_from_medication',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'taking_warfarin_or_anticoagulant',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'on_syringe_driver',
        },
      ],
    },
    {
      type: 'section',
      label: 'Current medication',
      questions: [
        {
          required: false,
          hasNote: true,
          questionId: 'medication_container',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'medication_taken_at_set_time',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'prn_medication',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'over_the_counter_medication_authorised',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'over_the_counter_medication_authorised_help_requested',
        },
      ],
    },
    {
      type: 'section',
      label: 'Medication risks',
      questions: [
        {
          required: false,
          hasNote: true,
          questionId: 'medication_decision_making_concerns',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'can_understand_their_medication',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'want_to_take_medication',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'forget_their_medication',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'can_read_medication_label',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'risk_of_overdose',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'excess_medication_available',
        },
      ],
    },
    {
      type: 'section',
      label: 'Functional',
      questions: [
        {
          required: false,
          hasNote: true,
          questionId: 'can_open_bottles_and_packets',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'can_pour_liquids',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'can_apply_remove_patches',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'can_apply_creams_ointments',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'can_instill_ear_drops',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'can_instill_eye_drops',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'can_instill_nasal_sprays',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'has_medication_that_requires_sharps',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'can_temper_with_medication',
        },
      ],
    },
    {
      type: 'section',
      label: 'Prescriptions',
      questions: [
        {
          required: false,
          hasNote: true,
          questionId: 'prescription_ordering_method',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'prescription_ordering_person',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'prescription_collection_method',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'medication_obtaining_problems',
        },
      ],
    },
    {
      type: 'section',
      label: 'Collecting',
      questions: [
        {
          required: false,
          hasNote: false,
          questionId: 'medicine_collection_frequency',
        },
      ],
    },
    {
      type: 'section',
      label: 'Storing',
      questions: [
        {
          required: false,
          hasNote: true,
          questionId: 'medication_stored_appropriately',
        },
        {
          required: false,
          hasNote: false,
          questionId: 'medication_storing_method',
        },
        {
          required: false,
          hasNote: false,
          questionId: 'medication_location',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'can_access_medication',
        },
      ],
    },
    {
      type: 'section',
      label: 'Disposing',
      questions: [
        {
          required: false,
          hasNote: true,
          questionId: 'excess_medication_disposition',
        },
        {
          required: false,
          hasNote: false,
          questionId: 'medication_disposition',
        },
      ],
    },
  ];

  return (
    <>
      <LocationBar section={customer ? `${customer?.firstName} ${customer?.lastName}` : 'Assessments'} page="Medication assessment" Icon={EditNote} />
      <AssessmentStepper steps={steps} customer={customer} id={assessmentId} type="medication" />
    </>
  );
};

export default MedicationAssessmentForm;
