import { gql, useMutation, useQuery } from '@apollo/client';
import { Assessment, AssesssmentListSearch, AssessmentByIdSearch } from '__generated__/graphql';

export const GET_ASSESSMENTS = gql`
  query GetAssessments($query: AssesssmentListSearch) {
    GetAssessments(query: $query) {
      id
      type
      createdAt
      state
      createdBySupportWorkerId
    }
  }
`;

export const GET_ASSESSMENT = gql`
  query GetAssessment($query: AssessmentByIdSearch) {
    GetAssessment(query: $query) {
      id
      type
      supportedPersonId
      name
      state
      createdAt
      createdBySupportWorkerId
      completedBySupportWorkerId
      completedAt
      updatedAt
      questions {
        id
        title
        answerType {
          options {
            value
            label
          }
          type
          variant
        }
      }
      answers {
        assessmentId
        questionId
        answer {
          type
          value
          choiceIds
          choiceId
        }
        note
        supportWorkerId
        updatedAt
      }
    }
  }
`;

export const SAVE_ASSESSMENT = gql`
  mutation SaveAssessment($input: AssessmentInput) {
    SaveAssessment(input: $input) {
      id
      message
      date
    }
  }
`;

interface AssessmentsResponse {
  GetAssessments: Assessment[];
}

interface GetAssessmentResponse {
  GetAssessment: Assessment;
}

const refetchAssessment = (input: { type: string; id: string; supportedPersonId: string }) => ({
  refetchQueries: [
    {
      query: GET_ASSESSMENTS,
      variables: {
        query: { type: input.type, supportedPersonId: input.supportedPersonId },
      },
    },
    {
      query: GET_ASSESSMENT,
      variables: {
        query: { type: input.type, id: input.id },
      },
    },
  ],
});
export const useGetAssessments = (query: AssesssmentListSearch) => {
  const { data, loading, error } = useQuery<AssessmentsResponse>(GET_ASSESSMENTS, {
    variables: {
      query: { ...query },
    },
  });

  return { assessments: data?.GetAssessments || [], loading, error };
};

export const useGetAssessment = (query: AssessmentByIdSearch) => {
  const { loading, data, error } = useQuery<GetAssessmentResponse>(GET_ASSESSMENT, {
    variables: {
      query: {
        ...query,
      },
    },
  });
  return { assessment: data?.GetAssessment, loading, error };
};

export const useSaveAssessment = (query: { type: string; id: string; supportedPersonId: string }) => {
  const [saveAssessment, mutationSaveAssessment] = useMutation(SAVE_ASSESSMENT, refetchAssessment(query));

  return { saveAssessment, mutationSaveAssessment };
};
