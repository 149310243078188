import { useLocation, useParams } from 'react-router-dom';
import { AssessmentLayout, Customer } from '__generated__/graphql';
import { LocationBar } from 'components';
import { EditNote } from '@mui/icons-material';
import { v4 as uuidv4 } from 'uuid';
import AssessmentStepper from '../components/AssessmentStepper';

const FinancialAssessmentForm = () => {
  const location = useLocation();
  const { id } = useParams();
  const { customer } = location.state as { customer: Customer };
  const assessmentId = id === 'new' ? uuidv4() : id;

  const steps: AssessmentLayout[] = [
    {
      type: 'section',
      label: 'Collection',
      questions: [
        {
          required: false,
          hasNote: false,
          questionId: 'pension_collection',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'pension_collection_frequency',
        },
        {
          required: false,
          hasNote: false,
          questionId: 'pension_collection_support_provider',
        },
        {
          required: false,
          hasNote: false,
          questionId: 'pension_collection_support_type',
        },
        {
          required: false,
          hasNote: false,
          questionId: 'money_collection',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'money_collection_frequency',
        },
        {
          required: false,
          hasNote: false,
          questionId: 'money_collection_support_provider',
        },
        {
          required: false,
          hasNote: false,
          questionId: 'money_collection_support_type',
        },
        {
          required: false,
          hasNote: false,
          questionId: 'allowance_collection',
        },
        {
          required: false,
          questionId: 'allowance_collection_frequency',
        },
        {
          required: false,
          hasNote: false,
          questionId: 'allowance_collection_support_provider',
        },
        {
          required: false,
          hasNote: false,
          questionId: 'allowance_collection_support_type',
        },
      ],
    },
    {
      type: 'section',
      label: 'Management',
      questions: [
        {
          required: false,
          hasNote: false,
          questionId: 'shopping_collection',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'shopping_collection_frequency',
        },
        {
          required: false,
          hasNote: false,
          questionId: 'shopping_collection_support_provider',
        },
        {
          required: false,
          hasNote: false,
          questionId: 'shopping_collection_support_type',
        },
        {
          required: false,
          hasNote: false,
          questionId: 'bill_payment',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'bill_payment_frequency',
        },
        {
          required: false,
          hasNote: false,
          questionId: 'bill_payment_support_provider',
        },
        {
          required: false,
          hasNote: false,
          questionId: 'bill_payment_support_type',
        },
        {
          required: false,
          hasNote: false,
          questionId: 'finance_management',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'finance_management_frequency',
        },
        {
          required: false,
          hasNote: false,
          questionId: 'finance_management_support_provider',
        },
        {
          required: false,
          hasNote: false,
          questionId: 'finance_management_support_type',
        },
      ],
    },
  ];

  return (
    <>
      <LocationBar section={customer ? `${customer?.firstName} ${customer?.lastName}` : 'Assessments'} page="Financial assessment" Icon={EditNote} />
      <AssessmentStepper steps={steps} customer={customer} id={assessmentId} type="financial" />
    </>
  );
};

export default FinancialAssessmentForm;
