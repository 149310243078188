import { useLocation, useParams } from 'react-router-dom';
import { AssessmentLayout, Customer } from '__generated__/graphql';
import { LocationBar } from 'components';
import { EditNote } from '@mui/icons-material';
import { v4 as uuidv4 } from 'uuid';
import AssessmentStepper from '../components/AssessmentStepper';

const BehaviourAssessmentForm = () => {
  const location = useLocation();
  const { id } = useParams();
  const { customer } = location.state as { customer: Customer };
  const assessmentId = id === 'new' ? uuidv4() : id;
  const steps: AssessmentLayout[] = [
    {
      type: 'section',
      label: 'General',
      questions: [
        {
          questionId: 'behaviours_challenge',
        },
        {
          hasNote: true,

          questionId: 'nature_behaviour(s)',
        },
        {
          questionId: 'describe_behaviour',
        },
        {
          questionId: 'how_frequently_behaviour_occur',
        },
        {
          questionId: 'how_long_behaviour_last_for',
        },
      ],
    },
    {
      type: 'section',
      label: 'Background',

      questions: [
        {
          questionId: 'known_reasons_behaviour',
        },
        {
          placeholder: 'e.g. an existing health condition, aspects of culture, or life history; recent changes to routine.',

          questionId: 'reasons_behaviour',
        },
        {
          hasNote: true,

          questionId: 'purpose_function_behaviour',
        },
        {
          hasNote: true,

          questionId: 'anything_trigger_behaviour',
        },
        {
          placeholder: 'e.g. attention; sensory stimulation.',
          questionId: 'getting_not_getting_from_behaviour(s)_makes_them_do_again',
        },
        {
          placeholder: 'i.e. impact on quality of life, independent living skills, and educational or occupational abilities.',
          questionId: 'impact_behaviour_on_{{firstname}}',
        },
        {
          placeholder: 'i.e. impact on quality of life.',
          questionId: 'impact_behaviour_on_others',
        },
      ],
    },
    {
      type: 'section',
      label: 'Support',

      questions: [
        {
          hasNote: true,
          questionId: 'already_behaviour_management_plan_place',
        },
        {
          placeholder: 'e.g. presents as friendly and mild-mannered.',
          questionId: 'mostly_calm_relaxed_how_do_they_present',
        },
        {
          placeholder: 'e.g. maintaining a calm environment.',
          questionId: 'how_should_carers_respond_during_phase-calm',
        },
        {
          placeholder: 'e.g. can become anxious and tearful.',
          questionId: 'behaviour_initially_starts_escalate_how_do_they_present',
        },
        {
          placeholder: 'e.g. carers should provide reassurance and speak calmly.',
          questionId: 'how_should_carers_respond_during_phase-escalate',
        },
        {
          placeholder: 'e.g. can begin shouting and lash out.',
          questionId: 'where_challenging_behaviour_occurs_how_present',
        },
        {
          placeholder: 'e.g. ensure safety and remove objects from reach that can be thrown.',
          questionId: 'how_should_carers_respond_during_phase-behaviour-occurs',
        },
        {
          placeholder: 'e.g. can remain tearful and become sleepy.',
          questionId: 'starts_relax_again_how_do_they_present',
        },
        {
          placeholder: 'e.g. sit with client quietly until they are feeling better.',
          questionId: 'how_should_carers_respond_during_phase-relax-again',
        },
        {
          hasNote: true,
          questionId: 'preventatitve_strategies',
        },
      ],
    },
    {
      type: 'section',
      label: 'Incident reporting',

      questions: [
        {
          placeholder: 'e.g. use Birdie to raise a concern and provide as much detail as possible.',
          questionId: 'how_should_carers_record_report_incidents_with_behaviour',
        },
      ],
    },
  ];

  return (
    <>
      <LocationBar section={customer ? `${customer?.firstName} ${customer?.lastName}` : 'Assessments'} page="Behaviour assessment" Icon={EditNote} />
      <AssessmentStepper steps={steps} customer={customer} id={assessmentId} type="behaviour" />
    </>
  );
};

export default BehaviourAssessmentForm;
