import { useLocation, useParams } from 'react-router-dom';
import { AssessmentLayout, Customer } from '__generated__/graphql';
import { LocationBar } from 'components';
import { EditNote } from '@mui/icons-material';
import { v4 as uuidv4 } from 'uuid';
import AssessmentStepper from '../components/AssessmentStepper';

const MovingAndHandlingAssessmentForm = () => {
  const location = useLocation();
  const { id } = useParams();
  const { customer } = location.state as { customer: Customer };
  const assessmentId = id === 'new' ? uuidv4() : id;

  const steps: AssessmentLayout[] = [
    {
      type: 'section',
      label: 'Client specific',
      questions: [
        {
          required: false,
          hasNote: false,
          questionId: 'fall_history',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'number_of_falls',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'weight_bear',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'pain_rest_or_movement',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'physique_move_or_hold',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'cognitive_impairment',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'challenging_behaviour',
        },
        {
          required: false,
          hasNote: false,
          questionId: 'client_specific',
        },
      ],
    },
    {
      type: 'section',
      label: 'Mobility',
      questions: [
        {
          required: false,
          hasNote: true,
          questionId: 'walking_independence',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'balancing_standing_independence',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'stairs_independence',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'from_sitting_to_moving_independence',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'sitting_balance',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'lying_down_independence',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'sitting_down_independence',
        },
        {
          required: false,
          hasNote: false,
          questionId: 'other_mobility',
        },
      ],
    },
    {
      type: 'section',
      label: 'Transfers',
      questions: [
        {
          required: false,
          hasNote: true,
          questionId: 'in_and_out_of_bed_independence',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'transferring_from_chair_to_shower_independence',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'transferring_from_shower_to_chair_independence',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'transferring_from_chair_to_bed_independence',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'transferring_from_bed_to_chair_independence',
        },
        {
          required: false,
          hasNote: false,
          questionId: 'other_transfers',
        },
      ],
    },
    {
      type: 'section',
      label: 'Working environment',
      questions: [
        {
          required: false,
          hasNote: true,
          questionId: 'floor_surfaces',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'bed_chair_low_to_ground',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'light_sufficient',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'risk_by_room_questions',
        },
        {
          required: false,
          hasNote: false,
          questionId: 'other_working_environment',
        },
      ],
    },
    {
      type: 'section',
      label: 'Equipment',
      questions: [
        {
          required: false,
          hasNote: false,
          questionId: 'hoist',
        },
        {
          required: false,
          hasNote: true,

          questionId: 'hoist_last_service_date',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'hoist_next_service_date',
        },
        {
          required: false,
          hasNote: false,
          questionId: 'stair_lift',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'stair_lift_last_service_date',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'stair_lift_next_service_date',
        },
        {
          required: false,
          hasNote: false,
          questionId: 'sling',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'sling_last_service_date',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'sling_next_service_date',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'rollator_frame',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'wheeled_trolley',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'transfer_banana_board',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'electric_profiling_bed',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'rails_and_bumpers',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'personal_alarm_fall_detector',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'commode',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'perching_stool',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'walking_stick',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'wheel_chair',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'riser_recliner_chair',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'pressure_relief_cushion',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'slide_sheets',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'electric_wheelchair',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'rotunda_stand',
        },
        {
          required: false,
          hasNote: false,
          questionId: 'other_equipment',
        },
      ],
    },
  ];

  return (
    <>
      <LocationBar section={customer ? `${customer?.firstName} ${customer?.lastName}` : 'Assessments'} page="Moving and handling assessment" Icon={EditNote} />
      <AssessmentStepper steps={steps} customer={customer} id={assessmentId} type="moving-and-handling" />
    </>
  );
};

export default MovingAndHandlingAssessmentForm;
