import { from, ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { Auth, Amplify } from 'aws-amplify';
import config from '../config';

Amplify.configure(config);

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_URL,
});

export const authLink = setContext(async (_, { headers }) => {
  const session = await Auth.currentSession();
  const accessToken = session.getIdToken();
  const jwt = accessToken.getJwtToken();

  return {
    headers: {
      ...headers,
      Authorization: jwt || null,
    },
  };
});

export const client = new ApolloClient({
  defaultOptions: {
    query: {
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
    },
    watchQuery: {
      fetchPolicy: 'network-only',
    },
    mutate: {
      errorPolicy: 'all',
    },
  },
  link: from([authLink, httpLink]),
  cache: new InMemoryCache({
    addTypename: false,
  }),
});
