import { useLocation, useParams } from 'react-router-dom';
import { AssessmentLayout, Customer } from '__generated__/graphql';
import { LocationBar } from 'components';
import { EditNote } from '@mui/icons-material';
import { v4 as uuidv4 } from 'uuid';
import AssessmentStepper from '../components/AssessmentStepper';

const ConditionSpecificAssessmentForm = () => {
  const location = useLocation();
  const { id } = useParams();
  const { customer } = location.state as { customer: Customer };
  const assessmentId = id === 'new' ? uuidv4() : id;
  const steps: AssessmentLayout[] = [
    {
      type: 'section',
      label: 'General',
      questions: [
        {
          questionId: 'confirmed_diagnosis',
        },
        {
          questionId: 'condition_diagnosed',
        },
      ],
    },
    {
      type: 'section',
      label: 'Capacity and decisions',
      questions: [
        {
          questionId: 'concerns_about_capacity_make_decisions_about_management_their_condition',
        },
      ],
    },
    {
      type: 'section',
      label: 'Impact',
      questions: [
        {
          questionId: 'impact_condition_on_{{firstname}}',
        },
        {
          questionId: 'how_condition_affect_on_good_day',
        },
        {
          questionId: 'how_condition_affect_on_bad_day',
        },
        {
          questionId: 'what_if_anything_most_difficult_relation_their_medical_condition_right_now',
        },
      ],
    },
    {
      type: 'section',
      label: 'Support',
      questions: [
        {
          questionId: 'manage_condition_themselves',
        },
        {
          questionId: 'how_manage_condition',
        },
        {
          hasNote: true,
          questionId: 'take_regular_medication_help_manage_their_condition',
        },
        {
          questionId: 'how_can_care_team_support_manage_their_condition',
        },
        {
          hasNote: true,
          questionId: 'services_not_currently_touch_with_could_help_with_managing_their_condition',
        },
        {
          hasNote: true,
          questionId: 'concerns_about_managing_their_condition_future',
        },
        {
          questionId: 'add_further_guidance_on_meeting_condition-specific_needs',
        },
      ],
    },
  ];

  return (
    <>
      <LocationBar section={customer ? `${customer?.firstName} ${customer?.lastName}` : 'Assessments'} page="Condition specific assessment" Icon={EditNote} />
      <AssessmentStepper steps={steps} customer={customer} id={assessmentId} type="condition-specific" />
    </>
  );
};

export default ConditionSpecificAssessmentForm;
