import { v4 as uuidv4 } from 'uuid';
import { useUser, UserContextProps } from 'context/userContext';
import Helmet from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import {
  useSaveConfirmationPracticesGroup,
  useGetConfirmationPracticeGroups,
  useGetConfirmationPracticeQuestions,
  useSaveConfirmationPracticeQuestion,
} from 'api/hooks/useConfirmationPractices';
import { useGetBuddiesForSupportWorker } from 'api/hooks/useBuddies';
import { ConfirmationPracticeGroup, ConfirmationPracticeQuestionInput } from '__generated__/graphql';
import { List } from '@mui/icons-material';
import { LocationBar, Loading, Error, Message } from 'components';
import TabSwitcher from 'components/TabSwitcher';
import Alert from 'components/Alert';
import { useState } from 'react';
import History from './History';
import { formatDateWithYMD } from '../../services/helpers';
import CreateConfirmationPracticesGroup from './CreateGroup';
import ConfirmationPracticeQuestionList from './ConfirmationPracticeQuestions/ConfirmationPracticeQuestionList';
import questions from './questions.json';

export default function ConfirmationPractices() {
  const navigate = useNavigate();
  const { user, userLoading } = useUser() as UserContextProps;
  const [openSnack, setOpenSnack] = useState<boolean>(false);

  const { buddies, buddiesLoading, buddiesError } = useGetBuddiesForSupportWorker({ supportWorkerId: user?.profile, teamId: user?.teamId });
  const { cpGroups, cpgLoading, cpgError } = useGetConfirmationPracticeGroups({ supportWorkerId: user?.profile });
  const { saveConfirmationPracticesGroup, mutationSaveConfirmationPracticesGroup } = useSaveConfirmationPracticesGroup({ supportWorkerId: user?.profile });
  const { saveConfirmationPracticeQuestion, mutationSaveConfirmationPracticeQuestion } = useSaveConfirmationPracticeQuestion({
    supportWorkerId: user?.profile,
  });
  const { confirmationPracticeQuestions, loading } = useGetConfirmationPracticeQuestions({ supportWorkerId: user?.profile });
  if (buddiesLoading || cpgLoading || loading || mutationSaveConfirmationPracticesGroup.loading) return <Loading />;
  if (buddiesError || cpgError || mutationSaveConfirmationPracticesGroup.error) return <Error />;

  const onEditVisit = (d: ConfirmationPracticeGroup) => {
    navigate(`/confirmation-practices/manage/${d.id}`);
  };
  const activeQuestions = confirmationPracticeQuestions.filter((cpq) => cpq.isActive);
  const combinedQuestions = [...activeQuestions, ...questions];
  const questionIds = combinedQuestions.map((q) => q.id);
  const onSaveGroup = async (isSolo: boolean, selectedBuddies: string[]) => {
    const id = uuidv4();
    await saveConfirmationPracticesGroup({
      variables: {
        input: {
          id,
          createdDateTime: Date.now(),
          isSolo,
          supportWorkerId: user?.profile,
          teamId: user?.teamId,
          buddies: selectedBuddies,
          questions: questionIds,
        },
      },
    });

    navigate(`/confirmation-practices/manage/${id}`);
  };

  const onSaveConfirmationPracticeQuestion = async (data: ConfirmationPracticeQuestionInput) => {
    await saveConfirmationPracticeQuestion({
      variables: {
        input: {
          ...data,
          supportWorkerId: user?.profile,
        },
      },
    });
    setOpenSnack(true);
  };
  const tabs = [
    { label: 'History', component: <History groups={cpGroups} onEditVisit={onEditVisit} /> },
    {
      label: 'Your Questions',
      component: (
        <ConfirmationPracticeQuestionList
          saveLoading={mutationSaveConfirmationPracticeQuestion.loading}
          onSaveConfirmationPracticeQuestion={onSaveConfirmationPracticeQuestion}
        />
      ),
    },
  ];
  const confirmationPracticeHasEntryForToday = () => {
    if (cpGroups && cpGroups.length > 0) {
      const answer = cpGroups.find((a) => formatDateWithYMD(a.createdDateTime || 0) === formatDateWithYMD(Date.now()));
      if (answer) {
        return true;
      }
    }
    return false;
  };
  if (userLoading) {
    return <Loading />;
  }
  return (
    <>
      <Helmet>
        <title>Confirmation Practices</title>
      </Helmet>
      <LocationBar section="Policies & Practices" page="Confirmation Practices" Icon={List} />
      <Message response={[mutationSaveConfirmationPracticeQuestion]} openSnack={openSnack} setOpenSnack={setOpenSnack} />

      <div className="flex flex-col gap-3">
        <div className="my-10 px-4 md:px-[5%]">
          <div className="flex flex-col">
            {!confirmationPracticeHasEntryForToday() ? (
              <CreateConfirmationPracticesGroup buddies={buddies} onSaveGroup={onSaveGroup} />
            ) : (
              <Alert title="You can only record one set of Confirmation Practice statements per day." message="" />
            )}
          </div>

          <div className="mt-10 sm:mt-16">
            <TabSwitcher tabs={tabs} />
          </div>
        </div>
      </div>
    </>
  );
}
