import { useLocation, useNavigate } from 'react-router-dom';
import { Customer } from '__generated__/graphql';
import AssessmentPage from '../AssessmentPage';

const EnvironmentAndFireList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { customer } = location.state as { customer: Customer };

  const assessmentNavigation = (id?: string) => {
    navigate(`/wellbeing-board/${customer?.id}/environment-and-fire/assessment/${id || 'new'}`, { state: { customer } });
  };

  return <AssessmentPage assessmentNavigation={assessmentNavigation} title="Environment and Fire" customer={customer} type="environment-and-fire" />;
};

export default EnvironmentAndFireList;
