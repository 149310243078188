import { useLocation, useParams } from 'react-router-dom';
import { AssessmentLayout, Customer } from '__generated__/graphql';
import { LocationBar } from 'components';
import { EditNote } from '@mui/icons-material';
import { v4 as uuidv4 } from 'uuid';
import AssessmentStepper from '../components/AssessmentStepper';

const CommunicationAssessmentForm = () => {
  const location = useLocation();
  const { id } = useParams();
  const { customer } = location.state as { customer: Customer };
  const assessmentId = id === 'new' ? uuidv4() : id;
  const steps: AssessmentLayout[] = [
    {
      type: 'section',
      label: 'Speech',
      questions: [
        {
          required: false,
          hasNote: true,
          questionId: 'speech_condition',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'speech_support',
        },
      ],
    },
    {
      type: 'section',
      label: 'Hearing',
      questions: [
        {
          required: false,
          hasNote: true,
          questionId: 'hearing_condition',
        },
        {
          required: false,

          hasNote: true,
          questionId: 'hearing_support',
        },
      ],
    },
    {
      type: 'section',
      label: 'Sight',
      questions: [
        {
          required: false,
          hasNote: true,
          questionId: 'sight_condition',
        },
        {
          required: false,

          hasNote: true,

          questionId: 'sight_support',
        },
      ],
    },
    {
      type: 'section',
      label: 'Comprehension',
      questions: [
        {
          required: false,
          hasNote: true,
          questionId: 'comprehension_level',
        },
      ],
    },
    {
      type: 'section',
      label: 'Expression',
      questions: [
        {
          required: false,
          hasNote: true,
          questionId: 'expression_level',
        },
        {
          required: false,
          hasNote: true,

          questionId: 'phone_problems',
        },
        {
          required: false,
          hasNote: true,

          questionId: 'emergency_summoning_problems',
        },
      ],
    },
    {
      type: 'section',
      label: 'Language',
      questions: [
        {
          questionId: 'english_appropriate',
          required: false,
          hasNote: false,
        },
        {
          required: false,

          hasNote: false,
          questionId: 'preferred_language',
        },
      ],
    },
    {
      type: 'section',
      label: 'Personal preferences',
      questions: [
        {
          required: false,
          hasNote: false,
          questionId: 'communication_method',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'contact_method',
        },
      ],
    },
  ];

  return (
    <>
      <LocationBar section={customer ? `${customer?.firstName} ${customer?.lastName}` : 'Assessments'} page="Communication assessment" Icon={EditNote} />
      <AssessmentStepper steps={steps} customer={customer} id={assessmentId} type="communication" />
    </>
  );
};

export default CommunicationAssessmentForm;
