import { useLocation, useParams } from 'react-router-dom';
import { AssessmentLayout, Customer } from '__generated__/graphql';
import { LocationBar } from 'components';
import { EditNote } from '@mui/icons-material';
import { v4 as uuidv4 } from 'uuid';
import AssessmentStepper from '../components/AssessmentStepper';

const MentalCapacityAssessmentForm = () => {
  const location = useLocation();
  const { id } = useParams();
  const { customer } = location.state as { customer: Customer };
  const assessmentId = id === 'new' ? uuidv4() : id;
  const steps: AssessmentLayout[] = [
    {
      type: 'section',
      label: 'Capacity details',
      questions: [
        {
          required: false,
          hasNote: true,
          questionId: 'brain_impairment',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'lacks_capacity_for_decisions',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'general_understanding_of_decisions',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'general_understanding_of_decision_consequences',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'understand_remember_compare_information',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'able_to_communicate_decision',
        },
        {
          required: false,
          hasNote: false,
          questionId: 'decision_making_support_details',
        },
        {
          required: false,
          hasNote: false,
          questionId: 'decision_making_wishes_details',
        },
      ],
    },
    {
      type: 'section',
      label: 'Conclusion',
      questions: [
        {
          required: true,
          hasNote: false,
          questionId: 'mental_capacity_consent',
        },
        // {
        //   type: 'score',
        //   id: 'assessment',
        //   from: [
        //     {
        //       type: 'question',
        //       questionId: 'mental_capacity_consent',
        //     },
        //   ],
        // },
      ],
    },
    {
      type: 'section',
      label: 'Best interest decision',
      questions: [
        {
          required: true,
          questionId: 'best_interest_decision_required',
        },
        {
          questionId: 'best_interest_decision_maker',
        },
        {
          questionId: 'best_interest_decision_maker_relation',
        },
      ],
    },
    {
      type: 'section',
      label: 'Circumstances',
      questions: [
        {
          placeholder: 'e.g. religious, cultural, moral',
          questionId: 'circumstances_values',
        },
        {
          questionId: 'relevant_circumstances',
        },
      ],
    },
    {
      type: 'section',
      label: 'Consultation',
      questions: [
        {
          questionId: 'who_was_involved',
        },
        {
          questionId: 'best_interests',
        },
        {
          hasNote: true,

          questionId: 'consultation_wishes',
        },
      ],
    },
    {
      type: 'section',
      label: 'IMCA',
      questions: [
        {
          questionId: 'has_imca',
        },
        {
          questionId: 'imca_date',
        },
        {
          questionId: 'imca_name',
        },
        {
          questionId: 'imca_organisation',
        },
        {
          questionId: 'imca_phone_number',
        },
        {
          questionId: 'imca_email',
        },
        {
          hasNote: true,

          placeholder:
            '​A referral is required if there is a decision relating to serious medical treatment or accommodation and the client does not have anyone to represent them',
          questionId: 'imca_referral',
        },
      ],
    },
    {
      type: 'section',
      label: 'Options',
      questions: [
        {
          questionId: 'first_option',
        },
        {
          questionId: 'benefits',
        },
        {
          questionId: 'risks',
        },
        {
          questionId: 'second_option',
        },
        {
          questionId: 'describe_second_option',
        },
        {
          questionId: 'second_option_benefits',
        },
        {
          questionId: 'second_option_risks',
        },
        {
          questionId: 'third_option',
        },
        {
          questionId: 'describe_third_option',
        },
        {
          questionId: 'third_option_benefits',
        },
        {
          questionId: 'third_option_risks',
        },
      ],
    },
    {
      type: 'section',
      label: 'Final decision',
      questions: [
        {
          questionId: 'final_decision_describe',
        },
        {
          questionId: 'reasons',
        },
        {
          questionId: 'other_options',
        },
        {
          hasNote: true,

          questionId: 'least_restrictive',
        },
        {
          hasNote: true,
          placeholder: 'Record who disagrees with the decision and why',
          questionId: 'disagree',
        },
        {
          questionId: 'intend_to_proceed',
        },
        {
          questionId: 'client_involved',
        },
      ],
    },
    {
      type: 'section',
      label: 'Review',
      questions: [
        {
          hasNote: true,
          placeholder: 'E.g. expected date of review',
          questionId: 'future_review',
        },
      ],
    },
  ];

  return (
    <>
      <LocationBar section={customer ? `${customer?.firstName} ${customer?.lastName}` : 'Assessments'} page="Mental capacity assessment" Icon={EditNote} />
      <AssessmentStepper steps={steps} customer={customer} id={assessmentId} type="mental-capacity" />
    </>
  );
};

export default MentalCapacityAssessmentForm;
