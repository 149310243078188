import { gql, useMutation, useQuery } from '@apollo/client';
import { RiskByIdSearch, RiskListSearch, Risk } from '__generated__/graphql';

export const GET_RISKS = gql`
  query GetRisks($query: RiskListSearch) {
    GetRisks(query: $query) {
      id
      risk
      mitigation
      createdAt
      createdBySupportWorkerId
      supportedPersonId
      relatedAssessment
      riskLevel
    }
  }
`;

export const GET_RISK = gql`
  query GetRisk($query: RiskByIdSearch) {
    GetRisk(query: $query) {
      id
      risk
      mitigation
      relatedAssessment
      riskLevel
      createdAt
      supportedPersonId
      createdBySupportWorkerId
    }
  }
`;

export const SAVE_RISK = gql`
  mutation SaveRisk($input: RiskInput) {
    SaveRisk(input: $input) {
      id
      message
      date
    }
  }
`;

interface RisksResponse {
  GetRisks: Risk[];
}

interface GetRiskResponse {
  GetRisk: Risk;
}

const refetchRisks = (input: RiskListSearch) => ({
  refetchQueries: [
    {
      query: GET_RISKS,
      variables: {
        query: { ...input },
      },
    },
  ],
});

export const useGetRisks = (query: RiskListSearch) => {
  const { data, loading, error } = useQuery<RisksResponse>(GET_RISKS, {
    variables: {
      query: {
        ...query,
      },
    },
  });

  return { risks: data?.GetRisks || [], loading, error };
};

export const useGetRisk = (query: RiskByIdSearch) => {
  const { loading, data, error } = useQuery<GetRiskResponse>(GET_RISK, {
    variables: {
      query: {
        ...query,
      },
    },
  });
  return { risk: data?.GetRisk, loading, error };
};

export const useSaveRisk = (query: RiskListSearch) => {
  const [saveRisk, mutationSaveRisk] = useMutation(SAVE_RISK, refetchRisks(query));

  return { saveRisk, mutationSaveRisk };
};
