import { ReactNode } from 'react';

interface FormLabelProps {
  label: string;
  children: ReactNode;
  asRow?: boolean;
  description?: string;
}

const FormLabel = ({ asRow, label, children, description }: FormLabelProps) => {
  return (
    <>
      <div className={`flex ${asRow ? 'flex-row items-center' : 'flex-col md:flex-row items-start gap-3 md:gap-7'}`}>
        <div className={`${asRow ? 'md:w-40' : 'w-72'} lg:w-72 text-gray-700 text-sm leading-sm font-medium`}>{label}</div>
        {children}
      </div>
      {description && <div className="text-gray-600 text-md leading-md mb-2">{description}</div>}
      {!asRow && <div className="h-px w-full bg-gray-100 my-6" />}
    </>
  );
};

export default FormLabel;
