import { useLocation, useParams } from 'react-router-dom';
import { AssessmentLayout, Customer } from '__generated__/graphql';
import { LocationBar } from 'components';
import { EditNote } from '@mui/icons-material';
import { v4 as uuidv4 } from 'uuid';
import AssessmentStepper from '../components/AssessmentStepper';

const SeizuresAssessmentForm = () => {
  const location = useLocation();
  const { id } = useParams();
  const { customer } = location.state as { customer: Customer };
  const assessmentId = id === 'new' ? uuidv4() : id;

  const steps: AssessmentLayout[] = [
    {
      type: 'section',
      label: 'General',
      questions: [
        {
          questionId: 'at_risk_having_seizures',
        },
      ],
    },
    {
      type: 'section',
      label: 'Capacity and decisions',

      questions: [
        {
          questionId: 'concerns_about_capacity_make_decisions_about_their_seizure_management',
        },
      ],
    },
    {
      type: 'section',
      label: 'Background',
      questions: [
        {
          questionId: 'confirmed_diagnosis_epilepsy',
        },
        {
          questionId: 'seizure_before',
        },
        {
          questionId: 'describe_last_seizure',
        },
        {
          questionId: 'know_their_last_seizure_happened',
        },
        {
          questionId: 'date_last_seizure',
        },
        {
          hasNote: true,
          questionId: 'type_seizure_known',
        },
        {
          questionId: 'how_frequently_do_seizures_occur',
        },
        {
          questionId: 'how_long_do_seizures_typically_last_for',
        },
        {
          hasNote: true,
          questionId: 'known_harm_been_caused_during_previous_seizures',
        },
      ],
    },
    {
      type: 'section',
      label: 'Management',
      questions: [
        {
          hasNote: true,

          questionId: 'seizure_management_plan_already_place',
        },
        {
          hasNote: true,
          questionId: 'take_regular_medication_prevent_their_seizures',
        },
        {
          hasNote: true,
          questionId: 'require_emergency_(prn)_medication_manage_their_seizures',
        },
        {
          questionId: 'warning_signs_seizure_might_about_happen',
        },
        {
          questionId: 'how_should_carers_support_seizure_happening',
        },
        {
          questionId: 'how_should_carers_support_immediately_after_seizure_occured',
        },
        {
          hasNote: true,
          questionId: 'equipment_aid_them_having_seizure',
        },
        {
          questionId: 'provide_additional_guidance_on_how_support_with_managing_their_seizures',
        },
      ],
    },
  ];

  return (
    <>
      <LocationBar section={customer ? `${customer?.firstName} ${customer?.lastName}` : 'Assessments'} page="Seizures assessment" Icon={EditNote} />
      <AssessmentStepper steps={steps} customer={customer} id={assessmentId} type="seizures" />
    </>
  );
};

export default SeizuresAssessmentForm;
