import { useEffect, useState } from 'react';
import { useUser, UserContextProps } from 'context/userContext';
import Helmet from 'react-helmet';
import Loading from 'components/Loading';
import { useGetSupportWorkers, useSaveSupportWorkerProfile } from 'api/hooks/useSupportWorkers';
import { useGetTeamShiftTBC } from 'api/hooks/useTeamShifts';
import { useGetAnswerStatus, useGetTeamAnswerStatus } from 'api/hooks/useConfirmationPractices';
import { useGetCustomers, useGetCustomersReviews } from 'api/hooks/useCustomers';
import { getFirstDayOfPayroll } from 'services/helpers';
import { useGetAllReportingHoursPeriod } from 'api/hooks/useReportingHours';
import { Avatar } from 'components';
import { ImageSizeType } from 'types/types';
import { useGetCourseReportsBySupportWorkerId, useRefreshSWCourseReports } from 'api/hooks/useCourseReport';
import TeamSelect from 'components/TeamSelect';
import Statistics from './Statistics';
import { SLACK_LINKS, SlackLinkType } from './components/Links';

export default function Dashboard() {
  const { user, userLoading } = useUser() as UserContextProps;
  const [teamId, setTeamId] = useState(user?.teamId);
  const { saveSupportWorkerProfile } = useSaveSupportWorkerProfile();
  const { teamShifts, loading } = useGetTeamShiftTBC({ teamId, rotaId: user?.rota?.id });
  const { answerStatus: ownStatus, loading: answerLoading } = useGetAnswerStatus({ supportWorkerId: user?.profile });
  const { teamAnswerStatus, loading: teamCPSLoading } = useGetTeamAnswerStatus({ teamId });
  const { customers: customersReviews, loading: customersReviewsLoading } = useGetCustomersReviews({ teamId });
  const { customers, loading: customersLoading } = useGetCustomers({ teamId });
  const { supportWorkers } = useGetSupportWorkers({});
  const { courseReports, courseReportsLoading } = useGetCourseReportsBySupportWorkerId({ supportWorkerId: user?.profile });

  const { refreshSWCourseReports, refreshSWCourseReportsLoading } = useRefreshSWCourseReports({
    supportWorkerId: '',
  });
  const { /* reportingHoursPeriod, */ loading: rhpLoading } = useGetAllReportingHoursPeriod({
    teamId,
    period: getFirstDayOfPayroll(Date.now()),
  });

  useEffect(() => {
    saveSupportWorkerProfile({
      variables: {
        input: { lastLoggedInDate: Date.now(), id: user?.profile },
      },
    });
    refreshSWCourseReports({
      variables: {
        input: { email: user?.email, supportWorkerId: user?.profile, teamId: user?.teamId },
      },
    });
  }, [saveSupportWorkerProfile, user?.profile, refreshSWCourseReports, user?.teamId, user?.email]);

  if (
    loading ||
    userLoading ||
    answerLoading ||
    refreshSWCourseReportsLoading ||
    teamCPSLoading ||
    customersLoading ||
    customersReviewsLoading ||
    rhpLoading ||
    courseReportsLoading
  ) {
    return <Loading />;
  }

  const teamSlack = teamId ? SLACK_LINKS[teamId as SlackLinkType] : '';

  return (
    <>
      <Helmet>
        <title>BelleVie Dashboard</title>
      </Helmet>
      <div className="bg-background py-10 px-4 md:px-[5%]">
        <div className="flex flex-row items-center justify-between">
          <div className="flex flex-row items-center gap-4">
            <div className="shrink-0">
              <Avatar alt={user?.fullName ?? ''} avatar={user?.avatar} size={ImageSizeType.LG2} borderWhite largeBorder />
            </div>
            <div>
              <div className="text-display-xs leading-display-xs sm:text-display-md sm:leading-display-md tracking-display-md font-semibold text-content-primary">
                Hello {user?.firstName}
              </div>
              <div className="text-md sm:text-lg font-medium text-content-secondary leading-md sm:leading-lg">{user?.teamName}</div>
            </div>
          </div>
          <div className="flex-row items-center gap-4 hidden lg:flex">
            {user?.permissions?.admin && <TeamSelect onSelectedTeam={setTeamId} selectedTeamId={teamId || ''} isContained />}
            <a
              type="button"
              className="border border-black border-opacity-20 px-6 py-4 rounded-[33px] text-content-primary text-lg leading-lg font-semibold"
              href={teamSlack}
              target="_blank"
              rel="noopener noreferrer"
            >
              Team Slack
            </a>
          </div>
        </div>
        {user?.permissions?.admin && (
          <div className="lg:hidden w-fit mt-4">
            <TeamSelect onSelectedTeam={setTeamId} selectedTeamId={teamId || ''} isContained />
          </div>
        )}
        <Statistics
          teamCPStatus={teamAnswerStatus}
          ownStatus={ownStatus}
          customers={customers}
          customersReviews={customersReviews}
          teamShifts={teamShifts}
          // reportingHoursPeriod={reportingHoursPeriod}
          supportWorkers={supportWorkers}
          courseReports={courseReports}
          teamId={teamId}
        />
      </div>
    </>
  );
}
