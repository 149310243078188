import { AssessmentAnswer, AssessmentLayout, AssessmentQuestion, AssessmentSubSection, Maybe } from '__generated__/graphql';
import questions from './data/questions.json';

export const useGenerateAnswers = (steps: AssessmentLayout[]): AssessmentAnswer[] => {
  const flattenQuestions: Maybe<AssessmentSubSection>[] = steps.flatMap((st) => st.questions);
  const answers = flattenQuestions.map((fl) => {
    const question: AssessmentQuestion | undefined = questions.find((q) => q.id === fl?.questionId);
    if (fl?.hasNote) {
      return {
        questionId: fl.questionId || '',
        answer: {
          type: question?.answerType?.type || '',
        },
        note: '',
      };
    } else {
      return {
        questionId: fl?.questionId || '',
        answer: {
          type: question?.answerType?.type || '',
        },
      };
    }
  });
  return answers;
};
