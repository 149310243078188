import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { SocialParticipationInputType, CustomerNeedType } from '__generated__/graphql';
import { useSaveCustomerNeed } from 'api/hooks/useCustomerNeed';
import { useGetCustomer } from 'api/hooks/useCustomers';
import { TextAreaFieldWithoutLabel } from 'components/Fields/TextAreaField';
import { Loading, LocationBar } from 'components';
import { ArrowBack, EditNote } from '@mui/icons-material';
import Message from 'components/Message';

interface LocationState {
  customerNeed: CustomerNeedType;
  teamId: string;
}

const SocialParticipationForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { supportedPersonId } = useParams();
  const { customerNeed, teamId } = location.state as LocationState;
  const { socialParticipation } = customerNeed || {};
  const [openSnack, setOpenSnack] = useState(false);

  const { saveCustomerNeed, mutationSaveCustomerNeed } = useSaveCustomerNeed({
    customerId: supportedPersonId,
  });
  const { customer, loading } = useGetCustomer({
    teamId,
    id: supportedPersonId,
  });

  const methods = useForm<SocialParticipationInputType>({
    mode: 'onSubmit',
  });

  const { handleSubmit, reset } = methods;

  useEffect(() => {
    reset(
      {
        ...socialParticipation,
      },
      {},
    );
  }, [reset, socialParticipation]);

  const onSubmit = async (data: SocialParticipationInputType) => {
    setOpenSnack(true);
    const input = { ...customerNeed, socialParticipation: data || socialParticipation };

    await saveCustomerNeed({
      variables: {
        input: {
          teamId,
          customerId: supportedPersonId,
          ...input,
        },
      },
    });
    navigate(-1);
  };

  if (loading) return <Loading />;

  return (
    <>
      <LocationBar
        section={customer ? `${customer?.firstName} ${customer?.lastName}` : 'People'}
        page="Social participation and involvement needs"
        Icon={EditNote}
      />
      <Message response={[mutationSaveCustomerNeed]} openSnack={openSnack} setOpenSnack={setOpenSnack} />
      <div className="my-10 px-4 md:px-[5%]">
        <button
          type="button"
          onClick={() => navigate(-1)}
          className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md flex items-center gap-2 mb-5"
        >
          <ArrowBack />
          <div>Go back</div>
        </button>

        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-8">
            <div className="space-y-6">
              <div className="space-y-4">
                <div className="text-gray-800 text-lg leading-lg font-bold">Social participation and activities</div>
                <div className="text-gray-700 text-sm leading-sm font-medium">
                  Can you describe your family member's social interactions and the activities they engage in?
                </div>
                <TextAreaFieldWithoutLabel name="socialParticipation" />
              </div>

              <div className="space-y-4">
                <div className="text-gray-800 text-lg leading-lg font-bold">Satisfaction with social life</div>
                <div className="text-gray-700 text-sm leading-sm font-medium">
                  How satisfied do you think your family member is with their current social life?
                </div>
                <TextAreaFieldWithoutLabel name="satisfactionWithSocialLife" />
              </div>

              <div className="space-y-4">
                <div className="text-gray-800 text-lg leading-lg font-bold">Unmet needs</div>
                <div className="text-gray-700 text-sm leading-sm font-medium">
                  What needs do you feel are not being met in relation to your family member's social participation and involvement?
                </div>
                <TextAreaFieldWithoutLabel name="unmetNeeds.answer" />
                <div className="text-gray-700 text-sm leading-sm font-medium">How do you feel these unmet needs impact them?</div>
                <TextAreaFieldWithoutLabel name="unmetNeeds.answer2" />
                <div className="text-gray-700 text-sm leading-sm font-medium">How do they impact you?</div>
                <TextAreaFieldWithoutLabel name="unmetNeeds.answer3" />
              </div>
            </div>

            <div className="flex justify-end">
              <button
                type="submit"
                disabled={mutationSaveCustomerNeed.loading}
                className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md flex items-center gap-2"
              >
                Save
              </button>
            </div>
          </form>
        </FormProvider>
      </div>
    </>
  );
};

export default SocialParticipationForm;
