import { useEffect, useRef, useState } from 'react';
import { FormProvider, useFieldArray, useForm, useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { AssessmentInput, AssessmentLayout, Customer } from '__generated__/graphql';
import { useGetAssessment, useSaveAssessment } from 'api/hooks/useAssessments';
import { TextAreaFieldWithoutLabel } from 'components/Fields/TextAreaField';
import { SelectField } from 'components/Fields/SelectField';
import { useUser } from 'context/userContext';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import Message from 'components/Message';
import Loading from 'components/Loading';
import { RadioFieldWithSignature } from 'components/Fields/RadioFieldWithSignature';
import questions from '../data/questions.json';
import { useGenerateAnswers } from '../hooks';

interface StepperProps {
  steps: AssessmentLayout[];
  customer: Customer;
  id?: string;
  type: string;
}

const Field = ({ fieldName, questionId, customer }: { fieldName: string; questionId?: string; customer: Customer }) => {
  const question = questions.find((q) => q.id === questionId);
  const answerType = question?.answerType;
  const { setValue } = useFormContext();
  const { user } = useUser();

  const type = answerType?.type;
  const title = question?.title?.replace('{{firstname}}', customer?.firstName || '') || '';
  const options = answerType?.options?.map((option) => ({
    label: option?.label || '',
    value: option?.value || '',
  }));

  const handleSignature = () => {
    setValue(`${fieldName}.supportWorkerId`, user?.profile);
    setValue(`${fieldName}.updatedAt`, new Date().getTime());
  };

  switch (type) {
    case 'multiple_choice':
    case 'single_choice':
      return <RadioFieldWithSignature name={`${fieldName}.answer.choiceId`} label={title} options={options || []} handleSignature={handleSignature} />;
    case 'free_text':
      return (
        <div className="space-y-4">
          <div className="text-gray-700 text-sm leading-sm font-medium">{title}</div>
          <TextAreaFieldWithoutLabel name={`${fieldName}.answer.value`} />
        </div>
      );
    case 'boolean':
      return (
        <RadioFieldWithSignature
          name={`${fieldName}.answer.choiceId`}
          label={title}
          options={[
            { label: 'Yes', value: 'Yes' },
            { label: 'No', value: 'No' },
          ]}
          handleSignature={handleSignature}
        />
      );
    case 'selector':
      return <SelectField name={`${fieldName}.answer.choiceId`} label={title} options={options || []} />;
    default:
      return null;
  }
};

const AssessmentStepper = ({ steps, customer, id, type }: StepperProps) => {
  const [activeStep, setActiveStep] = useState(0);
  const [openSnack, setOpenSnack] = useState(false);
  const navigate = useNavigate();
  const { assessment, loading } = useGetAssessment({ id, type });
  const { saveAssessment, mutationSaveAssessment } = useSaveAssessment({
    id: id || '',
    type,
    supportedPersonId: customer?.id || '',
  });
  const answers = useGenerateAnswers(steps);
  const { user } = useUser();
  const formRef = useRef<HTMLDivElement>(null);

  const methods = useForm<AssessmentInput>({
    defaultValues: {
      id,
      answers,
      type,
      supportedPersonId: customer?.id,
      name: `${customer?.firstName} ${customer?.lastName}`,
    },
  });

  const { fields } = useFieldArray({
    control: methods.control,
    name: 'answers',
  });

  const { handleSubmit, reset, setValue } = methods;

  useEffect(() => {
    reset({ ...assessment });
  }, [assessment, reset]);

  const activeFields = steps[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const isFirstStep = activeStep === 0;

  const handleNext = async () => {
    handleSubmit(onSubmit)();
    setActiveStep((prevStep) => prevStep + 1);
    formRef.current?.scrollTo(0, 0);
  };

  const handlePrev = () => {
    setActiveStep((prevStep) => prevStep - 1);
    formRef.current?.scrollTo(0, 0);
  };

  const onSubmit = async (data: AssessmentInput) => {
    setOpenSnack(true);

    const input: AssessmentInput = {
      ...data,
      id,
      state: isLastStep || data.completedAt ? 'Complete' : 'In progress',
      createdAt: data.createdAt || new Date().getTime(),
      updatedAt: new Date().getTime(),
      createdBySupportWorkerId: data.createdBySupportWorkerId || user?.profile,
    };

    await saveAssessment({
      variables: {
        input: {
          ...input,
        },
      },
    });
  };

  const lastStep = () => {
    setValue('completedAt', new Date().getTime());
    setValue('completedBySupportWorkerId', user?.profile);
    handleSubmit(onSubmit)();

    navigate(-1);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="flex flex-col h-full my-6 px-4 md:px-[5%]">
      <Message response={[mutationSaveAssessment]} openSnack={openSnack} setOpenSnack={setOpenSnack} />
      <div className="flex flex-col sm:flex-row items-center justify-between mb-5 gap-2">
        <button
          type="button"
          onClick={() => navigate(-1)}
          className="w-fit text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md flex items-center gap-2"
        >
          <ArrowBack />
          <div>Go back</div>
        </button>
        <div className="flex flex-row items-center justify-center gap-2">
          {!isFirstStep && (
            <button
              type="button"
              onClick={handlePrev}
              className="w-fit text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md flex items-center gap-2"
            >
              Previous
            </button>
          )}
          {isLastStep ? (
            <button
              type="button"
              onClick={lastStep}
              className="w-fit text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md flex items-center gap-2"
            >
              Submit
            </button>
          ) : (
            <button
              type="button"
              onClick={handleNext}
              className="w-fit text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md flex items-center gap-2"
            >
              Next
            </button>
          )}
        </div>
      </div>
      <FormProvider {...methods}>
        <div className="flex items-center justify-end">
          <div className="w-full sm:w-1/3 bg-gray-200 rounded-full h-2.5">
            <div className="bg-primary-700 h-2.5 rounded-full" style={{ width: `${((activeStep + 1) / steps.length) * 100}%` }} />
          </div>
        </div>

        <div className="flex-1 overflow-auto py-4" ref={formRef}>
          <div className="space-y-6">
            <h2 className="text-gray-800 text-xl leading-xl font-bold">{activeFields.label}</h2>
            {fields.map((field, index) => {
              const question = activeFields.questions.find((q) => q?.questionId === field?.questionId);
              if (question) {
                return (
                  <div key={index} className="space-y-4">
                    <Field fieldName={`answers.${index}`} questionId={question.questionId || ''} customer={customer} />
                    {question?.hasNote && (
                      <div className="space-y-4">
                        <div className="text-gray-700 text-sm leading-sm font-medium">Additional detail</div>
                        <TextAreaFieldWithoutLabel name={`answers.${index}.note`} />
                      </div>
                    )}
                  </div>
                );
              }
              return null;
            })}
          </div>
        </div>

        <div className="p-4 space-y-2 bg-white flex flex-col items-center justify-center">
          {isLastStep ? (
            <button
              type="button"
              onClick={lastStep}
              disabled={mutationSaveAssessment.loading || loading}
              className="xl:w-1/3 lg:w-1/2 w-full  text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md"
            >
              Submit
            </button>
          ) : (
            <button
              type="button"
              onClick={handleNext}
              disabled={mutationSaveAssessment.loading || loading}
              className="xl:w-1/3 lg:w-1/2 w-full text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md flex items-center justify-center gap-2"
            >
              Next
              <ArrowForward />
            </button>
          )}
          <button
            type="button"
            onClick={() => {
              handleSubmit(onSubmit)();
              navigate(-1);
            }}
            disabled={mutationSaveAssessment.loading || loading}
            className="xl:w-1/3 lg:w-1/2 w-full text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md flex items-center justify-center gap-2"
          >
            Save and close
          </button>
          <button
            type="button"
            onClick={() => navigate(-1)}
            className="xl:w-1/3 lg:w-1/2 w-full text-gray-700 bg-white border-2 border-gray-300 rounded-lg px-5 py-2.5 font-semibold text-md leading-md"
          >
            Cancel
          </button>
        </div>
      </FormProvider>
    </div>
  );
};

export default AssessmentStepper;
