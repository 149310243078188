import { useNavigate } from 'react-router-dom';
import { Customer } from '__generated__/graphql';
import { useGetCustomerNeed } from 'api/hooks/useCustomerNeed';

interface WellbeingBoardNeedsProps {
  customer: Customer | undefined;
}

const WellbeingBoardNeeds = ({ customer }: WellbeingBoardNeedsProps) => {
  const navigate = useNavigate();
  const { customerNeed } = useGetCustomerNeed({ customerId: customer?.id });

  const needs = [
    {
      label: 'Control over daily life',
      onClick: () => navigate(`/wellbeing-board/${customer?.id}/control-form/`, { state: { customerNeed, teamId: customer?.teamId } }),
    },
    {
      label: 'Personal cleanliness and comfort',
      onClick: () => navigate(`/wellbeing-board/${customer?.id}/personal-cleanliness-form/`, { state: { customerNeed, teamId: customer?.teamId } }),
    },
    {
      label: 'Food and drink',
      onClick: () => navigate(`/wellbeing-board/${customer?.id}/food-and-drink-form/`, { state: { customerNeed, teamId: customer?.teamId } }),
    },
    {
      label: 'Personal safety',
      onClick: () => navigate(`/wellbeing-board/${customer?.id}/personal-safety-form/`, { state: { customerNeed, teamId: customer?.teamId } }),
    },
    {
      label: 'Social participation & involvement',
      onClick: () => navigate(`/wellbeing-board/${customer?.id}/social-participation-form/`, { state: { customerNeed, teamId: customer?.teamId } }),
    },
    {
      label: 'Occupation',
      onClick: () => navigate(`/wellbeing-board/${customer?.id}/occupation-form/`, { state: { customerNeed, teamId: customer?.teamId } }),
    },
    {
      label: 'Accommodation cleanliness & comfort',
      onClick: () => navigate(`/wellbeing-board/${customer?.id}/accommodation-form/`, { state: { customerNeed, teamId: customer?.teamId } }),
    },
    {
      label: 'Dignity',
      onClick: () => navigate(`/wellbeing-board/${customer?.id}/dignity-form/`, { state: { customerNeed, teamId: customer?.teamId } }),
    },
  ];

  return (
    <div className="mt-4">
      <div className="text-gray-800 text-lg leading-lg font-bold mb-4 ml-2">{`Overall assessment of ${customer?.firstName}'s needs`}</div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {needs.map(({ label, onClick }) => (
          <button
            type="button"
            key={label}
            onClick={onClick}
            className="flex items-center justify-between w-full p-4 text-left bg-white border border-gray-200 rounded-lg shadow-sm hover:bg-gray-50"
          >
            <span className="text-gray-700 font-medium">{label}</span>
          </button>
        ))}
      </div>
    </div>
  );
};

export default WellbeingBoardNeeds;
