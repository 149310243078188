import { useLocation, useNavigate } from 'react-router-dom';
import { Customer } from '__generated__/graphql';
import AssessmentPage from '../AssessmentPage';

const ConditionSpecificList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { customer } = location.state as { customer: Customer };

  const assessmentNavigation = (id?: string) => {
    navigate(`/wellbeing-board/${customer?.id}/condition-specific/assessment/${id || 'new'}`, { state: { customer } });
  };

  return <AssessmentPage assessmentNavigation={assessmentNavigation} title="Condition Specific" customer={customer} type="condition-specific" />;
};

export default ConditionSpecificList;
