import { Controller, useFormContext } from 'react-hook-form';
import { SelectOption } from 'types/types';
import ReactMultiSelect from 'components/MultiSelect';
import FormLabel from '../FormLabel';
import { isValueRequired } from './validators';

interface MultiSelectFieldProps {
  label: string;
  name: string;
  asRow?: boolean;
  options: SelectOption[];
  disabled?: boolean;
  loading?: boolean;
  searchable?: boolean;
  testId?: string;
  isRequired?: boolean;
}

interface MultiSelectFieldWithOutLabelProps {
  name: string;
  options: SelectOption[];
  disabled?: boolean;
  loading?: boolean;
  searchable?: boolean;
  testId?: string;
  isRequired?: boolean;
}

function MultiSelectFieldWithoutLabel({ name, options, disabled = false, loading = false, searchable, testId, isRequired }: MultiSelectFieldWithOutLabelProps) {
  const { control, register } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={{ validate: isRequired ? isValueRequired : undefined }}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        const selectedValues = options.filter((option) => value?.includes(option.value));
        return (
          <>
            <ReactMultiSelect
              formRef={register(name).ref}
              testId={testId}
              isLoading={loading}
              disabled={disabled}
              options={options}
              onChange={(selected) => onChange(selected.map((option) => option.value))}
              selectedValue={selectedValues}
              searchable={searchable}
            />
            <p className="mt-2 text-sm text-error-600 dark:text-green-500">
              <span className="font-medium">{error?.message}</span>
            </p>
          </>
        );
      }}
    />
  );
}

function MultiSelectField(props: MultiSelectFieldProps) {
  return (
    <FormLabel asRow={props.asRow} label={props.label}>
      <div className="w-full lg:w-1/2">
        <MultiSelectFieldWithoutLabel {...props} />
      </div>
    </FormLabel>
  );
}

export { MultiSelectField, MultiSelectFieldWithoutLabel };
