import { useLocation, useParams } from 'react-router-dom';
import { AssessmentLayout, Customer } from '__generated__/graphql';
import { LocationBar } from 'components';
import { EditNote } from '@mui/icons-material';
import { v4 as uuidv4 } from 'uuid';
import AssessmentStepper from '../components/AssessmentStepper';

const EnvironmentAndFireAssessmentForm = () => {
  const location = useLocation();
  const { id } = useParams();
  const { customer } = location.state as { customer: Customer };
  const assessmentId = id === 'new' ? uuidv4() : id;

  const steps: AssessmentLayout[] = [
    {
      type: 'section',
      label: 'Outside',
      questions: [
        {
          questionId: 'parking_available',
        },
        {
          placeholder: 'e.g. directly outside the property, on the road.',
          questionId: 'where_parking_available',
        },
        {
          hasNote: true,
          questionId: 'risks_with_parking',
        },
        {
          questionId: 'how_will_these_risks_mitigated',
        },
        {
          questionId: 'steps_railings_outside_property',
        },
        {
          hasNote: true,
          questionId: 'risks_with_steps_railings',
        },
        {
          hasNote: true,
          questionId: 'how_will_these_risks_mitigated_steps_railings',
        },
        {
          hasNote: true,
          questionId: 'risks_with_outside_entrance',
        },
        {
          questionId: 'how_will_these_risks_mitigated_outside_entrance',
        },
        {
          hasNote: true,
          questionId: 'risks_with_doorbell',
        },
        {
          questionId: 'how_will_these_risks_mitigated_doorbell',
        },
      ],
    },
    {
      type: 'section',
      label: 'Security',
      questions: [
        {
          hasNote: true,
          questionId: 'concerns_with_locks_on_doors',
        },
        {
          questionId: 'how_will_these_risks_mitigated_locks_doors',
        },
        {
          hasNote: true,
          questionId: 'risks_with_keys_windows_doors',
        },
        {
          questionId: 'how_will_these_risks_mitigated_windows_doors',
        },
        {
          questionId: 'cctv_installed',
        },
        {
          placeholder: 'e.g. in the living room.',
          questionId: 'where_cctv_located',
        },
        {
          hasNote: true,
          questionId: 'risks_with_cctv',
        },
        {
          questionId: 'how_will_these_risks_mitigated_cctv',
        },
        {
          hasNote: true,
          questionId: 'other_risks_related_entrance',
        },
        {
          questionId: 'how_will_these_risks_mitigated_related_entrance',
        },
      ],
    },
    {
      type: 'section',
      label: 'Utilities',
      questions: [
        {
          placeholder: 'e.g. in the outside cupboard.',
          questionId: 'where_gas_cut_off_point_located',
        },
        {
          hasNote: true,
          questionId: 'risks_with_gas_cut_off_point',
        },
        {
          questionId: 'how_will_these_risks_mitigated_gas_cut_off',
        },
        {
          placeholder: 'e.g. in the outside cupboard.',
          questionId: 'where_electricity_point_located',
        },
        {
          hasNote: true,
          questionId: 'risks_with_electricity_cut_off_point',
        },
        {
          questionId: 'how_will_these_risks_mitigated_electricity_cut_off',
        },
        {
          placeholder: 'e.g. in the outside cupboard.',
          questionId: 'where_water_cut_off_point_located',
        },
        {
          hasNote: true,
          questionId: 'risks_with_water_cut_off_point',
        },
        {
          questionId: 'how_will_these_risks_mitigated_water_cut_off',
        },
      ],
    },
    {
      type: 'section',
      label: 'Storage',
      questions: [
        {
          placeholder: 'e.g. in the front garden.',
          questionId: 'where_rubbish_storage_located',
        },
        {
          hasNote: true,
          questionId: 'risks_with_rubbish_storage',
        },
        {
          questionId: 'how_will_these_risks_mitigated_rubbish_storage',
        },
        {
          placeholder: 'e.g. in the kitchen drawer next to the sink.',
          questionId: 'where_medication_stored',
        },
        {
          hasNote: true,
          questionId: 'risks_with_medication_storage',
        },
        {
          questionId: 'how_will_these_risks_mitigated_medication_storage',
        },
      ],
    },
    {
      type: 'section',
      label: 'Visitors and pets',
      questions: [
        {
          hasNote: true,
          questionId: 'risks_with_other_residents_visitors',
        },
        {
          questionId: 'how_will_these_risks_mitigated_residents_visitors',
        },
        {
          questionId: 'pets_property',
        },
        {
          questionId: 'pets_have',
        },
        {
          hasNote: true,
          questionId: 'risks_with_pets',
        },
        {
          questionId: 'how_will_these_risks_mitigated_risks_pets',
        },
      ],
    },
    {
      type: 'section',
      label: 'Living area',
      questions: [
        {
          hasNote: true,
          questionId: 'risks_with_doorway',
        },
        {
          questionId: 'how_will_these_risks_mitigated_doorway',
        },
        {
          hasNote: true,
          questionId: 'risks_with_walkway',
        },
        {
          questionId: 'how_will_these_risks_mitigated_walkway',
        },
        {
          hasNote: true,
          questionId: 'risks_with_rugs_carpets',
        },
        {
          questionId: 'how_will_these_risks_mitigated_rugs_carpets',
        },
        {
          hasNote: true,
          questionId: 'risks_with_living_area_lighting',
        },
        {
          questionId: 'how_will_these_risks_mitigated_living_area',
        },
        {
          hasNote: true,
          questionId: 'risks_with_electrical_cords_living_area',
        },
        {
          questionId: 'how_will_these_risks_mitigated_cords_living_area',
        },
        {
          hasNote: true,
          questionId: 'other_risks_living_area',
        },
        {
          questionId: 'how_will_these_risks_mitigated_other_risks_living_area',
        },
      ],
    },
    {
      type: 'section',
      label: 'Kitchen',
      questions: [
        {
          hasNote: true,
          questionId: 'risks_with_taps',
        },
        {
          questionId: 'how_will_these_risks_mitigated_taps',
        },
        {
          hasNote: true,
          questionId: 'risks_with_electrical_cords_kitchen',
        },
        {
          questionId: 'how_will_these_risks_mitigated_cords_kitchen',
        },
        {
          hasNote: true,
          questionId: 'risks_with_kitchen_flooring',
        },
        {
          questionId: 'how_will_these_risks_mitigated_kitchen_flooring',
        },
        {
          hasNote: true,
          questionId: 'risks_with_dishes_pots_pans_cutlery',
        },
        {
          questionId: 'how_will_these_risks_mitigated_pans_cutlery',
        },
        {
          hasNote: true,
          questionId: 'risks_with_fridge_freezer',
        },
        {
          questionId: 'how_will_these_risks_mitigated_fridge_freezer',
        },
        {
          hasNote: true,
          questionId: 'risks_with_cooker_oven_hob',
        },
        {
          questionId: 'how_will_these_risks_mitigated_oven_hob',
        },
        {
          hasNote: true,
          questionId: 'other_risks_kitchen',
        },
        {
          questionId: 'how_will_these_risks_mitigated_other_risks_kitchen',
        },
      ],
    },
    {
      type: 'section',
      label: 'Bathroom',
      questions: [
        {
          hasNote: true,
          questionId: 'risks_with_shower_bath',
        },
        {
          questionId: 'how_will_these_risks_mitigated_shower_bath',
        },
        {
          hasNote: true,
          questionId: 'risks_with_toilet',
        },
        {
          questionId: 'how_will_these_risks_mitigated_toilet',
        },
        {
          hasNote: true,
          questionId: 'risks_with_bathroom_flooring',
        },
        {
          questionId: 'how_will_these_risks_mitigated_bathroom_flooring',
        },
        {
          hasNote: true,

          questionId: 'other_risks_bathroom',
        },
        {
          questionId: 'how_will_these_risks_mitigated_other_risks_bathroom',
        },
      ],
    },
    {
      type: 'section',
      label: 'Bedroom',
      questions: [
        {
          hasNote: true,
          questionId: 'risks_with_bed',
        },
        {
          questionId: 'how_will_these_risks_mitigated_bed',
        },
        {
          hasNote: true,
          questionId: 'risks_with_bedroom_flooring',
        },
        {
          questionId: 'how_will_these_risks_mitigated_bedroom_flooring',
        },
        {
          hasNote: true,
          questionId: 'concerns_with_electrical_cords_bedroom',
        },
        {
          questionId: 'how_will_these_risks_mitigated_electrical_cords_bedroom',
        },
        {
          hasNote: true,
          questionId: 'other_risks_bedroom',
        },
        {
          questionId: 'how_will_these_risks_mitigated_other_risks_bedroom',
        },
      ],
    },
    {
      type: 'section',
      label: 'Fire prevention',
      questions: [
        {
          placeholder: 'e.g. in the kitchen and in the hall.',
          questionId: 'where_smoke_detector(s)_located',
        },
        {
          hasNote: true,
          questionId: 'risks_with_smoke_detectors',
        },
        {
          questionId: 'how_will_these_risks_mitigated_smoke_detector',
        },
        {
          hasNote: true,
          questionId: 'risks_with_smokers_property',
        },
        {
          questionId: 'how_will_these_risks_mitigated_smokers_property',
        },
        {
          placeholder: 'e.g. the fire blanket is in the kitchen.',
          questionId: 'where_fire_fighting_equipment_located',
        },
        {
          hasNote: true,
          questionId: 'risks_with_fire_fighting_equipment',
        },
        {
          questionId: 'how_will_these_risks_mitigated_fighting_equipment',
        },
        {
          placeholder: 'e.g. via the front door and back door.',
          questionId: 'where_escape_routes_located',
        },
        {
          hasNote: true,
          questionId: 'risks_with_escape_routes',
        },
        {
          questionId: 'how_will_these_risks_mitigated_escape_routes',
        },
        {
          placeholder: 'e.g. exit along the hall and out the front or back door.',
          questionId: 'escape_plan',
        },
        {
          hasNote: true,

          questionId: 'risks_with_escape_plan',
        },
        {
          questionId: 'how_will_these_risks_mitigated_escape_plan',
        },
        {
          hasNote: true,
          questionId: 'other_risks_related_fire_prevention',
        },
        {
          questionId: 'how_will_these_risks_mitigated_fire_prevention',
        },
      ],
    },
    {
      type: 'section',
      label: 'Carbon monoxide',
      questions: [
        {
          placeholder: 'e.g. in the living room.',
          questionId: 'where_carbon_monoxide_detector_located',
        },
        {
          hasNote: true,
          questionId: 'risks_with_carbon_monoxide_detector',
        },
        {
          questionId: 'how_will_these_risks_mitigated_carbon_monoxide_detector',
        },
        {
          hasNote: true,
          questionId: 'lone_worker_risks_related_environment_fire_aware_of',
        },
        {
          questionId: 'how_will_these_risks_mitigated_lone_worker',
        },
      ],
    },
  ];

  return (
    <>
      <LocationBar section={customer ? `${customer?.firstName} ${customer?.lastName}` : 'Assessments'} page="Environment and fire assessment" Icon={EditNote} />
      <AssessmentStepper steps={steps} customer={customer} id={assessmentId} type="environment-and-fire" />
    </>
  );
};

export default EnvironmentAndFireAssessmentForm;
