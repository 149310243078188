/* eslint-disable max-len */
import { ReactNode, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

interface TabSwitcherProps {
  defaultTab?: number;
  tabs: { label: string; component: ReactNode; testId?: string }[];
}
export default function TabSwitcher({ tabs, defaultTab = 0 }: TabSwitcherProps) {
  const [selectedTab, setSelectedTab] = useState(defaultTab);
  const [searchParams, setSearchParams] = useSearchParams();

  const handleTabChange = (newValue: number) => {
    const updatedParams = new URLSearchParams(searchParams);
    updatedParams.set('tab', newValue.toString());
    setSearchParams(updatedParams, { replace: true });
    setSelectedTab(newValue);
  };

  return (
    <>
      <div className="mb-4 border-b border-gray-200 dark:border-gray-700">
        <ul
          className="w-full border-b-2 border-gray-100 flex items-center gap-10 -mb-px overflow-auto"
          id="default-styled-tab"
          data-tabs-toggle="#default-styled-tab-content"
          role="tablist"
        >
          {tabs.map((tab, index) => (
            <li className="me-2" role="presentation">
              <button
                key={tab.label}
                data-cy={tab.testId || `tab-${index}`}
                type="button"
                className={`${
                  selectedTab === index ? 'text-primary-900 border-primary-700 border-b-2' : 'text-gray-500'
                } text-md leading-md font-semibold pb-4 shrink-0 `}
                onClick={() => handleTabChange(index)}
              >
                {tab.label}
              </button>
            </li>
          ))}
        </ul>
      </div>
      <div id="default-styled-tab-content">
        {tabs.map((tab, index) => (
          <div className={` w-full ${index === selectedTab ? '' : 'hidden'}`} id="styled-profile" role="tabpanel" aria-labelledby="profile-tab">
            <p className="text-sm text-gray-500 dark:text-gray-400">{tab.component}</p>
          </div>
        ))}
      </div>
    </>
  );
}
