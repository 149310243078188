import { useLocation, useParams } from 'react-router-dom';
import { AssessmentLayout, Customer } from '__generated__/graphql';
import { LocationBar } from 'components';
import { EditNote } from '@mui/icons-material';
import { v4 as uuidv4 } from 'uuid';
import AssessmentStepper from '../components/AssessmentStepper';

const COSHHAssessmentForm = () => {
  const location = useLocation();
  const { id } = useParams();
  const { customer } = location.state as { customer: Customer };
  const assessmentId = id === 'new' ? uuidv4() : id;

  const steps: AssessmentLayout[] = [
    {
      type: 'section',
      label: 'Usage',
      questions: [
        {
          questionId: 'activity_substance_used_for',
          placeholder: 'e.g. cleaning the toilet',
        },
        {
          hasNote: true,
          questionId: 'how_often_activity_carried_out',
        },
        {
          placeholder: 'e.g. 150ml, or as much as is needed',
          questionId: 'how_much_substance_used_at_time',
        },
        {
          placeholder: 'e.g. in the toilet in both bathrooms',
          questionId: 'where_substance_being_used',
        },
        {
          placeholder: 'e.g. in the cupboard under the sink',
          questionId: 'where_substance_stored_not_use',
        },
      ],
    },
    {
      type: 'section',
      label: 'Type of hazard',
      questions: [
        {
          questionId: 'category_substance_fit_into',
        },
        {
          hasNote: true,
          questionId: 'type_substance_it',
        },
        {
          hasNote: true,
          questionId: 'route_exposure',
        },
      ],
    },
    {
      type: 'section',
      label: 'Risk management',
      questions: [
        {
          questionId: 'who_at_risk_exposure',
        },
        {
          placeholder: 'e.g. the product could burn skin if in direct contact',
          questionId: 'risks_health',
        },
        {
          placeholder: 'e.g. windows should be kept open when in use.',
          questionId: 'how_will_exposure_substance_controlled',
        },
        {
          hasNote: true,
          questionId: 'ppe_needed_using_substance',
        },
        {
          placeholder: 'e.g. general bin with lid screwed on to avoid spillage',
          questionId: 'how_should_substance_and_or_contaminated_containers_disposed_of',
        },
        {
          placeholder: 'e.g if in contact with eyes, rinse immediately with cold water.',
          questionId: 'first_aid_measures_needed_if_exposed_substance',
        },
      ],
    },
    {
      type: 'section',
      label: 'Risk rating',
      questions: [
        {
          questionId: 'risk_rating_product_following_control_measures',
        },
      ],
    },
  ];

  return (
    <>
      <LocationBar section={customer ? `${customer?.firstName} ${customer?.lastName}` : 'Assessments'} page="COSHH assessment" Icon={EditNote} />
      <AssessmentStepper steps={steps} customer={customer} id={assessmentId} type="coshh" />
    </>
  );
};

export default COSHHAssessmentForm;
