import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { DignityInputType, CustomerNeedType } from '__generated__/graphql';
import { useSaveCustomerNeed } from 'api/hooks/useCustomerNeed';
import { useGetCustomer } from 'api/hooks/useCustomers';
import { TextAreaFieldWithoutLabel } from 'components/Fields/TextAreaField';
import { LocationBar } from 'components';
import { ArrowBack, EditNote } from '@mui/icons-material';
import Message from 'components/Message';

interface LocationState {
  customerNeed: CustomerNeedType;
  teamId: string;
}

const DignityForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { supportedPersonId } = useParams();
  const { customerNeed, teamId } = location.state as LocationState;
  const { dignity } = customerNeed || {};
  const [openSnack, setOpenSnack] = useState(false);

  const { saveCustomerNeed, mutationSaveCustomerNeed } = useSaveCustomerNeed({
    customerId: supportedPersonId,
  });
  const { customer } = useGetCustomer({
    teamId,
    id: supportedPersonId,
  });
  const methods = useForm<DignityInputType>({
    mode: 'onSubmit',
  });

  const { handleSubmit, reset } = methods;

  useEffect(() => {
    reset(
      {
        ...dignity,
      },
      {},
    );
  }, [reset, dignity]);

  const onSubmit = async (data: DignityInputType) => {
    setOpenSnack(true);
    const input = { ...customerNeed, dignity: data || dignity };

    await saveCustomerNeed({
      variables: {
        input: {
          teamId,
          customerId: supportedPersonId,
          ...input,
        },
      },
    });
    navigate(-1);
  };

  return (
    <>
      <LocationBar section={customer ? `${customer?.firstName} ${customer?.lastName}` : 'People'} page="Dignity needs" Icon={EditNote} />
      <Message response={[mutationSaveCustomerNeed]} openSnack={openSnack} setOpenSnack={setOpenSnack} />
      <div className="my-10 px-4 md:px-[5%]">
        <button
          type="button"
          onClick={() => navigate(-1)}
          className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md flex items-center gap-2 mb-5"
        >
          <ArrowBack />
          <div>Go back</div>
        </button>

        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-8">
            <div className="space-y-6">
              <div className="space-y-4">
                <div className="text-gray-800 text-lg leading-lg font-bold">Interactions and respect</div>
                <div className="text-gray-700 text-sm leading-sm font-medium">
                  Tell us how your family member would like us to be respectful in our interactions?
                </div>
                <TextAreaFieldWithoutLabel name="interactionsAndRespect" />
              </div>

              <div className="space-y-4">
                <div className="text-gray-800 text-lg leading-lg font-bold">Compromised dignity</div>
                <div className="text-gray-700 text-sm leading-sm font-medium">
                  Are there areas where you feel your family member's dignity could be compromised in our care?
                </div>
                <TextAreaFieldWithoutLabel name="compromisedAndRespect" />
              </div>

              <div className="space-y-4">
                <div className="text-gray-800 text-lg leading-lg font-bold">Unmet or anticipated needs</div>
                <div className="text-gray-700 text-sm leading-sm font-medium">
                  What needs do you feel are not or will not be met in relation to your family member's dignity?
                </div>
                <TextAreaFieldWithoutLabel name="unmetNeeds.answer" />
                <div className="text-gray-700 text-sm leading-sm font-medium">How do you feel these unmet or anticipated needs will impact them?</div>
                <TextAreaFieldWithoutLabel name="unmetNeeds.answer2" />
                <div className="text-gray-700 text-sm leading-sm font-medium">How do/will they impact you?</div>
                <TextAreaFieldWithoutLabel name="unmetNeeds.answer3" />
              </div>
            </div>

            <div className="flex justify-end">
              <button
                type="submit"
                disabled={mutationSaveCustomerNeed.loading}
                className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md flex items-center gap-2"
              >
                Save
              </button>
            </div>
          </form>
        </FormProvider>
      </div>
    </>
  );
};

export default DignityForm;
