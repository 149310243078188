import { Controller, useFormContext } from 'react-hook-form';
import FormLabel from '../FormLabel';

interface SelectOption {
  label: string;
  value: string | number;
}

interface RadioFieldWithSignatureProps {
  name: string;
  options: SelectOption[];
  disabled?: boolean;
  isRequired?: boolean;
  testId?: string;
  label: string;
  asCol?: boolean;
  handleSignature?: () => void;
}

const RadioFieldWithSignature = ({
  label,
  name,
  options,
  disabled = false,
  isRequired,
  testId,
  asCol = false,
  handleSignature,
}: RadioFieldWithSignatureProps) => {
  const { control, register } = useFormContext();

  const validate = (value: string) => {
    if (!value) {
      return 'Answer is required';
    }
    return true;
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        validate: isRequired ? validate : undefined,
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <FormLabel label={label}>
            <div ref={register(name).ref} className={`flex flex-wrap gap-2 ${asCol ? 'flex-col space-y-2' : 'flex-row'} ${asCol ? 'w-full' : 'w-fit'}`}>
              {options.map((option, index) => (
                <button
                  data-cy={`${testId}-${index}`}
                  key={index}
                  type="button"
                  disabled={disabled}
                  className={`
                    px-4 py-2.5 text-sm leading-sm font-medium
                    rounded-lg border border-gray-300
                    ${value === option.value ? 'bg-primary-700 text-white border-primary-700' : 'text-gray-700 hover:bg-gray-50'}
                    flex items-center justify-center
                    ${asCol ? 'w-full' : 'w-fit'}
                  `}
                  onClick={() => {
                    onChange(option.value);
                    if (handleSignature) {
                      handleSignature();
                    }
                  }}
                >
                  {option.label}
                </button>
              ))}
            </div>
            {error && (
              <p className="mt-2 text-sm text-error-600">
                <span className="font-medium">{error.message}</span>
              </p>
            )}
          </FormLabel>
        );
      }}
    />
  );
};

export { RadioFieldWithSignature };
