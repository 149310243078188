import { useLocation, useParams } from 'react-router-dom';
import { AssessmentLayout, Customer } from '__generated__/graphql';
import { LocationBar } from 'components';
import { EditNote } from '@mui/icons-material';
import { v4 as uuidv4 } from 'uuid';
import AssessmentStepper from '../components/AssessmentStepper';

const EndOfLifeAssessmentForm = () => {
  const location = useLocation();
  const { id } = useParams();
  const { customer } = location.state as { customer: Customer };
  const assessmentId = id === 'new' ? uuidv4() : id;
  const steps: AssessmentLayout[] = [
    {
      type: 'section',
      label: 'General',
      questions: [
        {
          questionId: 'does_need_end_of_life',

          required: false,
          hasNote: false,
        },
        {
          questionId: 'is_comfortable_discussing_topic',

          required: false,
        },
        {
          required: false,
          hasNote: true,
          questionId: 'anyone_else_present_during_discussion',
        },
        {
          hasNote: true,
          required: false,
          questionId: 'does_require_communication_support',
        },
        {
          hasNote: true,
          required: false,
          questionId: 'already_has_end_of_life_in_place',
        },
        {
          required: false,
          hasNote: true,
          questionId: 'topics_to_avoid_or_encourage',
        },
      ],
    },
    {
      type: 'section',
      label: 'Capacity',
      questions: [
        {
          questionId: 'concerns_around_ability_consent',
        },
      ],
    },
    {
      type: 'section',
      label: 'Documentation',
      questions: [
        {
          questionId: 'lpa_in_place_health_welfare',
        },
        {
          questionId: 'lpa_certificate_number_health_welfare',
        },
        {
          questionId: 'lpa_in_place_property_financial',
        },
        {
          questionId: 'lpa_certificate_number_property_financial',
        },
        {
          questionId: 'adrt',
        },
        {
          placeholder: 'e.g. study desk drawer',
          questionId: 'where_is_it_kept_adrt',
        },
        {
          questionId: 'respect_plan_in_place',
        },
        {
          placeholder: 'e.g. study desk drawer',
          questionId: 'where_is_it_kept_respect',
        },
        {
          questionId: 'made_a_will',
        },
        {
          placeholder: 'e.g. study desk drawer',
          questionId: 'where_is_it_kept_will',
        },
        {
          questionId: 'named_executor_will',
        },
        {
          questionId: 'opt_out_organ_donation',
        },
        {
          questionId: 'organ_donation_number',
        },
      ],
    },
    {
      type: 'section',
      label: 'Wishes and preferences',
      questions: [
        {
          questionId: 'health_deterioration_where_be_cared',
        },
        {
          questionId: 'other-details',
        },
        {
          placeholder: 'e.g. client is happy to take painkillers as and when needed.',
          questionId: 'thoughts_pain_management',
        },
        {
          questionId: 'specific_care_plan',
        },
        {
          placeholder: 'e.g. study desk drawer',
          questionId: 'where_is_it_kept_care_plan',
        },
        {
          hasNote: true,
          questionId: 'treatments_dont_want',
        },
        {
          placeholder: 'e.g. having loved ones around as much as possible.',
          questionId: 'important_final_days_hours',
        },
        {
          placeholder: 'e.g. to have the curtains open during the day; to sleep with the light on.',
          questionId: 'wishes_final_days_hours',
        },
        {
          placeholder: 'e.g. particular friends and/or family members.',
          questionId: 'present_final_days_hours',
        },
        {
          placeholder: 'e.g. being uncomfortable or in pain.',
          questionId: 'worry_final_days_hours',
        },
        {
          placeholder: 'e.g. smells, music, photos',
          questionId: 'comfort_final_days_hours',
        },
        {
          hasNote: true,
          questionId: 'religious_cultural_beliefs',
        },
        {
          questionId: 'buried_or_cremated',
        },
        {
          placeholder: 'e.g. where the funeral should be held.',
          questionId: 'funeral_wishes',
        },
      ],
    },
    {
      type: 'section',
      label: 'Other',
      questions: [
        {
          questionId: 'further_guidance_eof_needs',
        },
      ],
    },
  ];

  return (
    <>
      <LocationBar section={customer ? `${customer?.firstName} ${customer?.lastName}` : 'Assessments'} page="End of life assessment" Icon={EditNote} />
      <AssessmentStepper steps={steps} customer={customer} id={assessmentId} type="end-of-life" />
    </>
  );
};

export default EndOfLifeAssessmentForm;
