import { useLocation, useParams } from 'react-router-dom';
import { AssessmentLayout, Customer } from '__generated__/graphql';
import { LocationBar } from 'components';
import { EditNote } from '@mui/icons-material';
import { v4 as uuidv4 } from 'uuid';
import AssessmentStepper from '../components/AssessmentStepper';

const CovidAssessmentForm = () => {
  const location = useLocation();
  const { id } = useParams();
  const { customer } = location.state as { customer: Customer };
  const assessmentId = id === 'new' ? uuidv4() : id;
  const steps: AssessmentLayout[] = [
    {
      type: 'section',
      label: 'General',
      questions: [
        {
          hasNote: true,
          questionId: 'currently_confirmed_suspected_case_covid-19',
        },
        {
          hasNote: true,
          questionId: 'previously_confirmed_suspected_case_covid-19',
        },
        {
          hasNote: true,

          questionId: 'considered_extremely_clinically_vulnerable_at_risk_covid-19',
        },
      ],
    },
    {
      type: 'section',
      label: 'Vaccine history',
      questions: [
        {
          questionId: 'received_their_first_dose_covid-19_vaccine',
        },
        {
          questionId: 'vaccine_did_receive',
        },
        {
          questionId: 'vaccine_administered',
        },
        {
          questionId: 'received_their_second_dose_covid-19_vaccine',
        },
        {
          questionId: 'vaccine_did_receive_second',
        },
        {
          questionId: 'vaccine_administered_second',
        },
        {
          questionId: 'received_their_first_booster_dose_covid-19_vaccine',
        },
        {
          questionId: 'vaccine_did_receive_first_booster',
        },
        {
          questionId: 'vaccine_administered_first_booster',
        },
      ],
    },
    {
      type: 'section',
      label: 'Isolating',
      questions: [
        {
          questionId: 'currently_isolating',
        },
        {
          placeholder: 'e.g. personal preference; clinically vulnerable family member.',

          questionId: 'reasons_currently_isolating',
        },
        {
          questionId: 'isolated_past',
        },
        {
          placeholder: 'e.g. personal preference; clinically vulnerable family member.',

          questionId: 'were_reasons_isolating_past',
        },
        {
          placeholder: 'e.g. number of visits temporarily increased or decreased.',

          questionId: 'been_impact_isolation_on_care_needs',
        },
      ],
    },
    {
      type: 'section',
      label: 'Risk management',
      questions: [
        {
          placeholder: 'e.g. carers opening windows during visits; reduction in number of individual carers visiting.',
          questionId: 'practices_place_control_risk_infection',
        },
        {
          placeholder: 'e.g. agency to immediately inform family and temporarily suspend visits.',
          questionId: 'procedure_should_followed_if_tests_positive_covid-19',
        },
        {
          placeholder: 'e.g. agency to support client with lateral flow and/or PCR test.',
          questionId: 'procedure_should_followed_if_carer_who_recently_visited_tests_positive_covid-19',
        },
      ],
    },
  ];

  return (
    <>
      <LocationBar section={customer ? `${customer?.firstName} ${customer?.lastName}` : 'Assessments'} page="Covid-19 assessment" Icon={EditNote} />
      <AssessmentStepper steps={steps} customer={customer} id={assessmentId} type="covid" />
    </>
  );
};

export default CovidAssessmentForm;
