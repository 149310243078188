import { useLocation, useParams } from 'react-router-dom';
import { AssessmentLayout, Customer } from '__generated__/graphql';
import { LocationBar } from 'components';
import { EditNote } from '@mui/icons-material';
import { v4 as uuidv4 } from 'uuid';
import AssessmentStepper from '../components/AssessmentStepper';

const DysphagiaAssessmentForm = () => {
  const location = useLocation();
  const { id } = useParams();
  const { customer } = location.state as { customer: Customer };
  const assessmentId = id === 'new' ? uuidv4() : id;
  const steps: AssessmentLayout[] = [
    {
      type: 'section',
      label: 'General',
      questions: [
        {
          questionId: 'confirmed_diagnosis_dysphagia',
        },
        {
          questionId: 'known_speech_language_therapy_team',
        },
        {
          hasNote: true,
          questionId: 'speech_language_therapy_(salt)_guidelines_eating_drinking',
        },
        {
          questionId: 'these_guidelines_being_followed',
        },
        {
          placeholder: 'e.g. because the client is refusing to follow them.',
          questionId: 'why_arent_these_guidelines_being_followed',
        },
      ],
    },
    {
      type: 'section',
      label: 'Choking history',
      questions: [
        {
          questionId: 'been_known_choking_incident_last_12_months',
        },
        {
          questionId: 'how_many_times_happened_last_12_months',
        },
        {
          questionId: 'eating_and_or_drinking_at_time_incident',
        },
        {
          placeholder: 'e.g. a sandwich; a cup of coffee.',
          questionId: 'being_eaten_and_or_drunk',
        },
        {
          questionId: 'choking_incident_additional_details',
        },
      ],
    },
    {
      type: 'section',
      label: 'Behavioural risks',
      questions: [
        {
          placeholder: 'e.g. eating non-food items.',

          hasNote: true,

          questionId: 'behaviours_increase_their_risk_choking',
        },
        {
          placeholder: 'e.g. observing during mealtimes.',

          questionId: 'strategies_place_manage_behaviour',
        },
      ],
    },
    {
      type: 'section',
      label: 'Eating and drinking risks',
      questions: [
        {
          questionId: 'dentures',
        },
        {
          questionId: 'usually_wear_these_eating_drinking',
        },
        {
          placeholder: 'e.g. because they find them uncomfortable; because they forget.',

          questionId: 'why_not_wear_their_dentures_eating_drinking',
        },
        {
          hasNote: true,
          questionId: 'frequently_continually_cough_before_during_after_eating',
        },
        {
          hasNote: true,
          questionId: 'frequently_continually_cough_before_during_after_drinking',
        },
        {
          hasNote: true,
          questionId: 'ever_difficulty_breathing_whilst_they_eating',
        },
        {
          hasNote: true,
          questionId: 'currently_able_chew_normal_diet',
        },
        {
          hasNote: true,
          questionId: 'started_eating_less',
        },
        {
          hasNote: true,
          questionId: 'hold_food_their_mouth',
        },
        {
          hasNote: true,
          questionId: 'lost_their_enjoyment_food',
        },
      ],
    },
    {
      type: 'section',
      label: 'Modifications',
      questions: [
        {
          questionId: 'food_texture_need_modified',
        },
        {
          hasNote: true,
          questionId: 'level_should_food_texture_according_iddsi_framework',
        },
        {
          questionId: 'drinks_texture_need_modified',
        },
        {
          hasNote: true,
          questionId: 'level_should_drinks_texture_according_iddsi_framework',
        },
        {
          questionId: 'how_should_these_prepared',
        },
        {
          questionId: 'where_thickening_powder_stored',
        },
        {
          questionId: 'measuring_jug_preparing_thickened_fluids',
        },
        {
          questionId: 'where_kept',
        },
        {
          hasNote: true,
          questionId: 'use_special_cutlery_cups_help_with_eating_drinking',
        },
      ],
    },
    {
      type: 'section',
      label: 'Medication',
      questions: [
        {
          questionId: 'take_oral_medication',
        },
        {
          questionId: 'difficulties_swallowing_their_oral_medication',
        },
        {
          questionId: 'strategies_place_help_take_their_medication_more_easily',
        },
      ],
    },
    {
      type: 'section',
      label: 'Other risks',
      questions: [
        {
          hasNote: true,
          questionId: 'other_risks_not_captured_here',
        },
      ],
    },
    {
      type: 'section',
      label: 'Support',
      questions: [
        {
          questionId: 'can_do_reduce_their_risk_choking',
        },
        {
          questionId: 'should_care_team_do_reduce_risk_choking',
        },
        {
          questionId: 'warning_signs_might_choking',
        },
        {
          questionId: 'how_should_carers_support_if_they_begin_choking',
        },
      ],
    },
  ];

  return (
    <>
      <LocationBar section={customer ? `${customer?.firstName} ${customer?.lastName}` : 'Assessments'} page="Dysphagia assessment" Icon={EditNote} />
      <AssessmentStepper steps={steps} customer={customer} id={assessmentId} type="dysphagia" />
    </>
  );
};

export default DysphagiaAssessmentForm;
