import { gql, useMutation, useQuery } from '@apollo/client';
import { CustomerNeedSearch, CustomerNeedType } from '__generated__/graphql';

export const GET_CUSTOMER_NEED = gql`
  query GetCustomerNeed($query: CustomerNeedSearch) {
    GetCustomerNeed(query: $query) {
      id
      teamId
      customerId
      accomodation {
        cleanlinessAndOrganisation
        barriersToInvolvement
        unmetNeeds {
          answer
          answer2
          answer3
        }
      }
      control {
        currentInvolvement
        feelingsAboutControl
        strengths
        unmetNeeds {
          answer
          answer2
          answer3
        }
      }
      dignity {
        interactionsAndRespect
        compromisedAndRespect
        unmetNeeds {
          answer
          answer2
          answer3
        }
      }
      foodAndDrink {
        eatingHabitsAndPreferences
        challengesToRoutine
        strengths
        unmetNeeds {
          answer
          answer2
          answer3
        }
      }
      occupation {
        enjoyableActivities
        incidentsAndConcerns
        unmetNeeds {
          answer
          answer2
          answer3
        }
      }
      personalCleanliness {
        hygieneRoutines
        challengesToRoutine
        strengths
        unmetNeeds {
          answer
          answer2
          answer3
        }
      }
      personalSafety {
        homeEnvironmentSaftey
        incidentsAndConcerns
        unmetNeeds {
          answer
          answer2
          answer3
        }
      }
      socialParticipation {
        socialParticipation
        satisfactionWithSocialLife
        unmetNeeds {
          answer
          answer2
          answer3
        }
      }
    }
  }
`;

export const SAVE_CUSTOMER_NEED = gql`
  mutation SaveCustomerNeed($input: CustomerNeedInputType) {
    SaveCustomerNeed(input: $input) {
      message
      date
    }
  }
`;

interface CustomerNeedResponse {
  GetCustomerNeed: CustomerNeedType;
}

export const refetchCustomerNeed = (input: CustomerNeedSearch) => ({
  refetchQueries: [
    {
      query: GET_CUSTOMER_NEED,
      variables: {
        query: {
          ...input,
        },
      },
    },
  ],
});

export const useGetCustomerNeed = (query: CustomerNeedSearch) => {
  const { data, loading, error } = useQuery<CustomerNeedResponse>(GET_CUSTOMER_NEED, {
    variables: {
      query: { ...query },
    },
  });

  return { customerNeed: data?.GetCustomerNeed || {}, loading, error };
};

export const useSaveCustomerNeed = (query: CustomerNeedSearch) => {
  const [saveCustomerNeed, mutationSaveCustomerNeed] = useMutation(SAVE_CUSTOMER_NEED, refetchCustomerNeed(query));
  return { saveCustomerNeed, mutationSaveCustomerNeed };
};
