import { useLocation, useNavigate } from 'react-router-dom';
import { Customer } from '__generated__/graphql';
import AssessmentPage from '../AssessmentPage';

const CommunicationList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { customer } = location.state as { customer: Customer };

  const assessmentNavigation = (id?: string) => {
    navigate(`/wellbeing-board/${customer?.id}/communication/assessment/${id || 'new'}`, { state: { customer } });
  };

  return <AssessmentPage assessmentNavigation={assessmentNavigation} title="Communication" customer={customer} type="communication" />;
};

export default CommunicationList;
