import { useNavigate } from 'react-router-dom';
import { Customer } from '__generated__/graphql';

interface WellbeingBoardAssessmentProps {
  customer: Customer | undefined;
}

const WellbeingBoardAssessment = ({ customer }: WellbeingBoardAssessmentProps) => {
  const navigate = useNavigate();

  const assessments = [
    {
      label: 'Behaviour',
      onClick: () => navigate(`/wellbeing-board/${customer?.id}/behaviour`, { state: { customer } }),
    },
    {
      label: 'Communication',
      onClick: () => navigate(`/wellbeing-board/${customer?.id}/communication`, { state: { customer } }),
    },
    {
      label: 'Condition specific',
      onClick: () => navigate(`/wellbeing-board/${customer?.id}/condition-specific`, { state: { customer } }),
    },
    {
      label: 'Control of substances hazardous to health',
      onClick: () => navigate(`/wellbeing-board/${customer?.id}/coshh`, { state: { customer } }),
    },
    {
      label: 'Covid-19',
      onClick: () => navigate(`/wellbeing-board/${customer?.id}/covid`, { state: { customer } }),
    },
    {
      label: 'Dysphagia',
      onClick: () => navigate(`/wellbeing-board/${customer?.id}/dysphagia`, { state: { customer } }),
    },
    {
      label: 'End of life',
      onClick: () => navigate(`/wellbeing-board/${customer?.id}/end-of-life`, { state: { customer } }),
    },
    {
      label: 'Environment and fire',
      onClick: () => navigate(`/wellbeing-board/${customer?.id}/environment-and-fire`, { state: { customer } }),
    },
    {
      label: 'Financial',
      onClick: () => navigate(`/wellbeing-board/${customer?.id}/financial`, { state: { customer } }),
    },
    {
      label: 'Medication',
      onClick: () => navigate(`/wellbeing-board/${customer?.id}/medication`, { state: { customer } }),
    },
    {
      label: 'Mental capacity',
      onClick: () => navigate(`/wellbeing-board/${customer?.id}/mental-capacity`, { state: { customer } }),
    },
    {
      label: 'Moving and handling',
      onClick: () => navigate(`/wellbeing-board/${customer?.id}/moving-and-handling`, { state: { customer } }),
    },
    {
      label: 'Seizures',
      onClick: () => navigate(`/wellbeing-board/${customer?.id}/seizures`, { state: { customer } }),
    },
  ];

  return (
    <div className="mt-4">
      <div className="text-gray-800 text-lg leading-lg font-bold mb-4 ml-2">
        {`Additional risk assessments required to meet ${customer?.firstName}'s needs`}
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {assessments.map(({ label, onClick }) => (
          <button
            type="button"
            key={label}
            onClick={onClick}
            className="flex items-center justify-between w-full p-4 text-left bg-white border border-gray-200 rounded-lg shadow-sm hover:bg-gray-50"
          >
            <span className="text-gray-700 font-medium">{label}</span>
          </button>
        ))}
      </div>
    </div>
  );
};

export default WellbeingBoardAssessment;
