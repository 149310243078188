import { useNavigate } from 'react-router-dom';
import { Customer } from '__generated__/graphql';

import { useGetSupportWorkers } from 'api/hooks/useSupportWorkers';
import { LocationBar } from 'components';
import { format } from 'date-fns';
import { ArrowBack, EditNote } from '@mui/icons-material';
import Avatar from 'components/Avatar';
import Loading from 'components/Loading';
import { useGetAssessments } from 'api/hooks/useAssessments';
import { useGetRisks } from 'api/hooks/useRisks';
import { ImageSizeType } from 'types/types';

interface AssessmentPageProps {
  assessmentNavigation: (id?: string) => void;
  title: string;
  customer: Customer;
  type: string;
}

const AssessmentPage = ({ assessmentNavigation, title, customer, type }: AssessmentPageProps) => {
  const { assessments, loading: loadingAssessments } = useGetAssessments({ supportedPersonId: customer?.id, type });
  const { risks, loading: loadingRisks } = useGetRisks({ supportedPersonId: customer?.id });
  const { supportWorkers } = useGetSupportWorkers({ teamId: customer?.teamId });
  const navigate = useNavigate();

  const filteredAssessments = assessments?.filter((assessment) => assessment.type === type);
  const filteredRisks = risks?.filter((risk) => risk.relatedAssessment?.includes(type));

  const navigateToNewRisk = () => {
    navigate(`/wellbeing-board/${customer?.id}/risks/new`, { state: { customer, type } });
  };

  if (loadingAssessments || loadingRisks) {
    return <Loading />;
  }

  return (
    <>
      <LocationBar section={customer ? `${customer?.firstName} ${customer?.lastName}` : 'Assessments'} page={title} Icon={EditNote} />
      <div className="my-10 px-4 md:px-[5%]">
        <button
          type="button"
          onClick={() => navigate(-1)}
          className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md flex items-center gap-2 mb-5"
        >
          <ArrowBack />
          <div>Go back</div>
        </button>
        <div className="space-y-4">
          <div className="flex justify-between items-center">
            <h2 className="text-gray-800 text-xl leading-xl font-bold">{customer?.firstName}'s Assessments</h2>
            <button
              type="button"
              onClick={() => assessmentNavigation()}
              className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md flex items-center gap-2"
            >
              New
            </button>
          </div>

          {filteredAssessments?.length === 0 ? (
            <div className="bg-white rounded-xl p-4 mt-4">
              <p className="text-gray-700 text-center text-md font-medium">
                {customer?.firstName} has no {type} assessments
              </p>
            </div>
          ) : (
            <div className="space-y-2">
              {filteredAssessments?.map((assessment, index) => {
                const supportWorker = supportWorkers?.find((sw) => sw.id === assessment.createdBySupportWorkerId);
                return (
                  <div key={index} onClick={() => assessmentNavigation(assessment.id)} className="bg-white rounded-xl p-4 cursor-pointer hover:bg-gray-50">
                    <div className="flex justify-between items-start">
                      <p className="text-gray-700 text-sm font-semibold">
                        {assessment.createdAt ? format(new Date(assessment.createdAt), 'd/MM/yyyy') : 'No created date'} - {assessment.state}
                      </p>
                    </div>
                    <div className="flex items-center mt-4">
                      <Avatar avatar={supportWorker?.avatar} alt={supportWorker?.fullName || ''} size={ImageSizeType.LG2} />
                      <p className="text-gray-600 text-xs ml-2">{supportWorker?.fullName}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>

        <div className="mt-8 space-y-4">
          <div className="flex justify-between items-center">
            <h2 className="text-gray-800 text-xl leading-xl font-bold">{customer?.firstName}'s Risks</h2>
            <button
              type="button"
              onClick={navigateToNewRisk}
              className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md flex items-center gap-2"
            >
              New Risk
            </button>
          </div>
          {filteredRisks?.length === 0 ? (
            <div className="bg-white rounded-xl p-4">
              <p className="text-gray-700 text-center text-md font-medium">
                {customer?.firstName} has no risks for {type}
              </p>
            </div>
          ) : (
            <div className="space-y-2">
              {filteredRisks.map((risk, index) => {
                const supportWorker = supportWorkers?.find((sw) => sw.id === risk.createdBySupportWorkerId);
                const getRiskClass = () => {
                  switch (risk.riskLevel) {
                    case 'Medium':
                      return 'bg-warning-400';
                    case 'High':
                      return 'bg-error-400';
                    default:
                      return 'bg-success-400';
                  }
                };

                return (
                  <div
                    key={index}
                    onClick={() => navigate(`/wellbeing-board/${customer?.id}/risks/${risk.id}`, { state: { customer, type } })}
                    className="bg-white rounded-xl p-4 cursor-pointer hover:bg-gray-50"
                  >
                    <div className="flex justify-between items-start">
                      <p className="text-gray-700 text-sm font-semibold">
                        {risk.createdAt ? format(new Date(risk.createdAt), 'd/MM/yyyy') : 'No created date'}
                      </p>
                      <div className={`${getRiskClass()} rounded-xl px-2 py-1 max-w-[30%]`}>
                        <p className="text-white text-xs font-semibold text-center">Risk: {risk.riskLevel}</p>
                      </div>
                    </div>
                    <p className="text-gray-700 text-sm font-semibold mt-2">{risk.risk}</p>
                    <div className="flex items-center mt-4">
                      <Avatar avatar={supportWorker?.avatar} alt={supportWorker?.fullName || ''} size={ImageSizeType.LG2} />
                      <p className="text-gray-600 text-xs ml-2">{supportWorker?.fullName}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AssessmentPage;
