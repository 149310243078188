import { useLocation, useNavigate } from 'react-router-dom';
import { Customer } from '__generated__/graphql';
import AssessmentPage from '../AssessmentPage';

const MovingAndHandlingList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { customer } = location.state as { customer: Customer };

  const assessmentNavigation = (id?: string) => {
    navigate(`/wellbeing-board/${customer?.id}/moving-and-handling/assessment/${id || 'new'}`, { state: { customer } });
  };

  return <AssessmentPage assessmentNavigation={assessmentNavigation} title="Moving and Handling" customer={customer} type="moving-and-handling" />;
};

export default MovingAndHandlingList;
