import { RouteObject } from 'react-router-dom';
import AgreedShiftsContainer from 'pages/AgreedShifts';
import ConfirmationPractices from 'pages/ConfirmationPractices';
import ManageConfirmationPractices from 'pages/ConfirmationPractices/Manage';
import Faq from 'pages/Faq';
import Guides from 'pages/Guides';
import LeadershipTeam from 'pages/LeadershipTeam';
import MagicNumber from 'pages/MagicNumber';
import SupportedPeopleList from 'pages/SupportedPeople/SupportedPeopleList';
import Maps from 'pages/Maps';
import MyShifts from 'pages/MyShifts';
import MyVisits from 'pages/MyVisits';
import ReportingHours from 'pages/ReportingHours';
import ManageReportingHours from 'pages/ReportingHours/ManageReportingHours';
import SubmitReportingHours from 'pages/ReportingHours/SubmitReportingHours';
import ReportingHoursList from 'pages/ReportingHours/ReportingHoursList';
import ReviewStats from 'pages/ReviewStats';
import RotaList from 'pages/SelectRota';
import EditProfile from 'pages/SupportedPeople/EditProfile';
import Profile from 'pages/SupportedPeople/Profile';
import TeamMeetings from 'pages/TeamMeetings/TeamMeetingList';
import TeamMeetingCreate from 'pages/TeamMeetings/TeamMeetingCreate';
import TeamMemberDetail from 'pages/TeamMembers/TeamMemberDetail';
import TeamMemberUpdate from 'pages/TeamMembers/TeamMemberUpdate';
import TeamSetup from 'pages/TeamSetup';
import TeamShifts from 'pages/TeamShifts';
import WellbeingBoard from 'pages/WellbeingBoard';
import WellbeingBoardCustomer from 'pages/WellbeingBoard/WellbeingBoardCustomer';
import Dashboard from 'pages/Dashboard';
import Room from 'pages/Chat';
import Policies from 'pages/Policies';
import TeamMembersList from 'pages/TeamMembers/TeamMembersList';
import FormList from 'pages/Forms/FormList';
import MedicalObvsDetail from 'pages/Forms/MedicalObvs/MedicalObvsDetail';
import AscotReviewCreate from 'pages/WellbeingBoard/AscotReview/AscotReviewCreate';
import AscotReviewUpdate from 'pages/WellbeingBoard/AscotReview/AscotReviewUpdate';
import AscotExpectedOutcomeUpdate from 'pages/WellbeingBoard/AscotExpectedOutcome/AscotExpectedOutcomeUpdate';
import TeamMeetingUpdate from 'pages/TeamMeetings/TeamMeetingUpdate';
import AscotExpectedOutcomeCreate from 'pages/WellbeingBoard/AscotExpectedOutcome/AscotExpectedOutcomeCreate';
import IncidentDetail from 'pages/Forms/Incident/IncidentDetail';
import ReportingHoursStatus from 'pages/ReportingHours/ReportingHoursStatus';
import ControlForm from 'pages/WellbeingBoard/WellbeingBoardCustomer/WellbeingBoardNeeds/forms/ControlForm';
import PersonalCleanlinessForm from 'pages/WellbeingBoard/WellbeingBoardCustomer/WellbeingBoardNeeds/forms/PersonalCleanlinessForm';
import DignityForm from 'pages/WellbeingBoard/WellbeingBoardCustomer/WellbeingBoardNeeds/forms/DignityForm';
import OccupationForm from 'pages/WellbeingBoard/WellbeingBoardCustomer/WellbeingBoardNeeds/forms/OccupationForm';
import FoodAndDrinkForm from 'pages/WellbeingBoard/WellbeingBoardCustomer/WellbeingBoardNeeds/forms/FoodAndDrinkForm';
import AccommodationForm from 'pages/WellbeingBoard/WellbeingBoardCustomer/WellbeingBoardNeeds/forms/AccommodationForm';
import SocialParticipationForm from 'pages/WellbeingBoard/WellbeingBoardCustomer/WellbeingBoardNeeds/forms/SocialParticipationForm';
import PersonalSafetyForm from 'pages/WellbeingBoard/WellbeingBoardCustomer/WellbeingBoardNeeds/forms/PersonalSafetyForm';
import BehaviourList from 'pages/WellbeingBoard/WellbeingBoardCustomer/WellbeingBoardAssessment/lists/BehaviourList';
import BehaviourAssessmentForm from 'pages/WellbeingBoard/WellbeingBoardCustomer/WellbeingBoardAssessment/forms/BehaviourAssessmentForm';
import CommunicationList from 'pages/WellbeingBoard/WellbeingBoardCustomer/WellbeingBoardAssessment/lists/CommunicationList';
import CommunicationAssessmentForm from 'pages/WellbeingBoard/WellbeingBoardCustomer/WellbeingBoardAssessment/forms/CommunicationAssessmentForm';
import SeizuresList from 'pages/WellbeingBoard/WellbeingBoardCustomer/WellbeingBoardAssessment/lists/SeizuresList';
import SeizuresAssessmentForm from 'pages/WellbeingBoard/WellbeingBoardCustomer/WellbeingBoardAssessment/forms/SeizuresAssessmentForm';
import FinancialList from 'pages/WellbeingBoard/WellbeingBoardCustomer/WellbeingBoardAssessment/lists/FinancialList';
import FinancialAssessmentForm from 'pages/WellbeingBoard/WellbeingBoardCustomer/WellbeingBoardAssessment/forms/FinancialAssessmentForm';
import COSHHList from 'pages/WellbeingBoard/WellbeingBoardCustomer/WellbeingBoardAssessment/lists/COSHHList';
import COSHHAssessmentForm from 'pages/WellbeingBoard/WellbeingBoardCustomer/WellbeingBoardAssessment/forms/COSHHAssessmentForm';
import CovidList from 'pages/WellbeingBoard/WellbeingBoardCustomer/WellbeingBoardAssessment/lists/CovidList';
import CovidAssessmentForm from 'pages/WellbeingBoard/WellbeingBoardCustomer/WellbeingBoardAssessment/forms/CovidAssessmentForm';
import DysphagiaList from 'pages/WellbeingBoard/WellbeingBoardCustomer/WellbeingBoardAssessment/lists/DysphagiaList';
import DysphagiaAssessmentForm from 'pages/WellbeingBoard/WellbeingBoardCustomer/WellbeingBoardAssessment/forms/DysphagiaAssessmentForm';
import EndOfLifeList from 'pages/WellbeingBoard/WellbeingBoardCustomer/WellbeingBoardAssessment/lists/EndOfLifeList';
import EndOfLifeAssessmentForm from 'pages/WellbeingBoard/WellbeingBoardCustomer/WellbeingBoardAssessment/forms/EndOfLifeAssessmentForm';
import ConditionSpecificList from 'pages/WellbeingBoard/WellbeingBoardCustomer/WellbeingBoardAssessment/lists/ConditionSpecificList';
import ConditionSpecificAssessmentForm from 'pages/WellbeingBoard/WellbeingBoardCustomer/WellbeingBoardAssessment/forms/ConditionSpecificAssessmentForm';
import EnvironmentAndFireList from 'pages/WellbeingBoard/WellbeingBoardCustomer/WellbeingBoardAssessment/lists/EnvironmentAndFireList';
import EnvironmentAndFireAssessmentForm from 'pages/WellbeingBoard/WellbeingBoardCustomer/WellbeingBoardAssessment/forms/EnvironmentAndFireAssessmentForm';
import MedicationList from 'pages/WellbeingBoard/WellbeingBoardCustomer/WellbeingBoardAssessment/lists/MedicationList';
import MedicationAssessmentForm from 'pages/WellbeingBoard/WellbeingBoardCustomer/WellbeingBoardAssessment/forms/MedicationAssessmentForm';
import MentalCapacityList from 'pages/WellbeingBoard/WellbeingBoardCustomer/WellbeingBoardAssessment/lists/MentalCapacityList';
import MentalCapacityAssessmentForm from 'pages/WellbeingBoard/WellbeingBoardCustomer/WellbeingBoardAssessment/forms/MentalCapacityAssessmentForm';
import MovingAndHandlingList from 'pages/WellbeingBoard/WellbeingBoardCustomer/WellbeingBoardAssessment/lists/MovingAndHandlingList';
import MovingAndHandlingAssessmentForm from 'pages/WellbeingBoard/WellbeingBoardCustomer/WellbeingBoardAssessment/forms/MovingAndHandlingAssessmentForm';
import RiskForm from 'pages/WellbeingBoard/WellbeingBoardCustomer/WellbeingBoardAssessment/forms/RiskForm';

export const publicRoutes: RouteObject[] = [
  { path: '/', element: <Dashboard /> },
  { path: '/chat', element: <Room /> },
  {
    path: '/confirmation-practices',
    children: [
      { index: true, element: <ConfirmationPractices /> },
      { path: 'manage/:groupId', element: <ManageConfirmationPractices /> },
    ],
  },
  { path: '/dashboard', element: <Dashboard /> },
  { path: '/faqs', element: <Faq /> },
  { path: '/guides', element: <Guides /> },
  { path: '/magic-number', element: <MagicNumber /> },
  { path: '/maps', element: <Maps /> },
  { path: '/my-shifts', element: <MyShifts /> },
  { path: '/my-visits', element: <MyVisits /> },
  { path: '/organisation-map', element: <LeadershipTeam /> },
  { path: '/policies', element: <Policies /> },
  {
    path: '/reporting-hours',
    children: [
      { index: true, element: <ReportingHours /> },
      { path: 'list', element: <ReportingHoursList /> },
      { path: 'manage/:teamId/:swId/:id', element: <ManageReportingHours /> },
      { path: 'submit/:teamId/:swId/:id', element: <SubmitReportingHours /> },
    ],
  },
  {
    path: '/reporting-hours-status',
    children: [{ index: true, element: <ReportingHoursStatus /> }],
  },
  { path: '/review-stats', element: <ReviewStats /> },

  { path: '/rota', element: <RotaList /> },
  { path: '/rota-templates', element: <AgreedShiftsContainer /> },

  {
    path: '/team-meetings',
    children: [
      { index: true, element: <TeamMeetings /> },
      { path: ':teamMeetingId/update', element: <TeamMeetingUpdate /> },
      { path: 'new', element: <TeamMeetingCreate /> },
    ],
  },
  {
    path: '/team-members',
    children: [
      { index: true, element: <TeamMembersList /> },
      { path: ':teamMemberId', element: <TeamMemberDetail /> },
      { path: ':teamMemberId/update', element: <TeamMemberUpdate /> },
    ],
  },
  { path: '/team-setup', element: <TeamSetup /> },

  { path: '/team-shifts', element: <TeamShifts /> },
  {
    path: '/supported-people',
    children: [
      { index: true, element: <SupportedPeopleList /> },
      { path: ':supportedPersonId', element: <Profile /> },
      { path: ':supportedPersonId/update', element: <EditProfile /> },
    ],
  },
  {
    path: '/wellbeing-board',
    children: [
      { index: true, element: <WellbeingBoard /> },
      { path: ':supportedPersonId', element: <WellbeingBoardCustomer /> },
      { path: ':supportedPersonId/review/:reviewId/ascot/create', element: <AscotReviewCreate /> },
      { path: ':supportedPersonId/ascot/:ascotReviewId', element: <AscotReviewUpdate /> },
      { path: ':supportedPersonId/ascot/:ascotReviewId/expected-outcome/create', element: <AscotExpectedOutcomeCreate /> },
      { path: ':supportedPersonId/ascot/:ascotReviewId/expected-outcome/:ascotExpectedOutcomeId/update', element: <AscotExpectedOutcomeUpdate /> },
      { path: ':supportedPersonId/control-form', element: <ControlForm /> },
      { path: ':supportedPersonId/personal-cleanliness-form', element: <PersonalCleanlinessForm /> },
      { path: ':supportedPersonId/dignity-form', element: <DignityForm /> },
      { path: ':supportedPersonId/food-and-drink-form', element: <FoodAndDrinkForm /> },
      { path: ':supportedPersonId/occupation-form', element: <OccupationForm /> },
      { path: ':supportedPersonId/accommodation-form', element: <AccommodationForm /> },
      { path: ':supportedPersonId/social-participation-form', element: <SocialParticipationForm /> },
      { path: ':supportedPersonId/personal-safety-form', element: <PersonalSafetyForm /> },

      { path: ':supportedPersonId/behaviour', element: <BehaviourList /> },
      { path: ':supportedPersonId/behaviour/assessment/:id', element: <BehaviourAssessmentForm /> },

      { path: ':supportedPersonId/communication', element: <CommunicationList /> },
      { path: ':supportedPersonId/communication/assessment/:id', element: <CommunicationAssessmentForm /> },

      { path: ':supportedPersonId/coshh', element: <COSHHList /> },
      { path: ':supportedPersonId/coshh/assessment/:id', element: <COSHHAssessmentForm /> },

      { path: ':supportedPersonId/covid', element: <CovidList /> },
      { path: ':supportedPersonId/covid/assessment/:id', element: <CovidAssessmentForm /> },

      { path: ':supportedPersonId/dysphagia', element: <DysphagiaList /> },
      { path: ':supportedPersonId/dysphagia/assessment/:id', element: <DysphagiaAssessmentForm /> },

      { path: ':supportedPersonId/end-of-life', element: <EndOfLifeList /> },
      { path: ':supportedPersonId/end-of-life/assessment/:id', element: <EndOfLifeAssessmentForm /> },

      { path: ':supportedPersonId/financial', element: <FinancialList /> },
      { path: ':supportedPersonId/financial/assessment/:id', element: <FinancialAssessmentForm /> },

      { path: ':supportedPersonId/seizures', element: <SeizuresList /> },
      { path: ':supportedPersonId/seizures/assessment/:id', element: <SeizuresAssessmentForm /> },

      { path: ':supportedPersonId/condition-specific', element: <ConditionSpecificList /> },
      { path: ':supportedPersonId/condition-specific/assessment/:id', element: <ConditionSpecificAssessmentForm /> },

      { path: ':supportedPersonId/environment-and-fire', element: <EnvironmentAndFireList /> },
      { path: ':supportedPersonId/environment-and-fire/assessment/:id', element: <EnvironmentAndFireAssessmentForm /> },

      { path: ':supportedPersonId/medication', element: <MedicationList /> },
      { path: ':supportedPersonId/medication/assessment/:id', element: <MedicationAssessmentForm /> },

      { path: ':supportedPersonId/mental-capacity', element: <MentalCapacityList /> },
      { path: ':supportedPersonId/mental-capacity/assessment/:id', element: <MentalCapacityAssessmentForm /> },

      { path: ':supportedPersonId/moving-and-handling', element: <MovingAndHandlingList /> },
      { path: ':supportedPersonId/moving-and-handling/assessment/:id', element: <MovingAndHandlingAssessmentForm /> },

      { path: ':supportedPersonId/risks/:id', element: <RiskForm /> },
    ],
  },
  {
    path: '/forms',
    children: [
      { index: true, element: <FormList /> },
      { path: 'medical-obvs/:formId', element: <MedicalObvsDetail /> },
      { path: 'medical-obvs/create', element: <MedicalObvsDetail /> },
      { path: 'incident/:formId', element: <IncidentDetail /> },
      { path: 'incident/create', element: <IncidentDetail /> },
    ],
  },
];
