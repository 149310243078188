import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Customer, RiskInput } from '__generated__/graphql';
import { Loading, LocationBar, Message } from 'components';
import { ArrowBack, EditNote } from '@mui/icons-material';
import { v4 as uuidv4 } from 'uuid';
import { useEffect, useState } from 'react';
import { TextAreaFieldWithoutLabel } from 'components/Fields/TextAreaField';
import { useGetRisk, useSaveRisk } from 'api/hooks/useRisks';
import { useUser } from 'context/userContext';
import { useForm, FormProvider } from 'react-hook-form';
import { RadioFieldWithSignature } from 'components/Fields/RadioFieldWithSignature';
import { MultiSelectField } from 'components/Fields/MultiSelectField';

const RiskForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const { customer, type } = location.state as { customer: Customer; type: string };
  const riskId = id === 'new' ? uuidv4() : id;
  const [openSnack, setOpenSnack] = useState(false);

  const { risk, loading } = useGetRisk({ id });
  const { saveRisk, mutationSaveRisk } = useSaveRisk({ supportedPersonId: customer?.id });
  const { user } = useUser();

  const methods = useForm<RiskInput>({
    defaultValues: {
      relatedAssessment: type ? [type] : [],
      supportedPersonId: customer?.id,
      riskLevel: 'Low',
    },
  });

  const { handleSubmit, reset } = methods;

  useEffect(() => {
    reset(
      {
        ...risk,
        createdAt: risk?.createdAt || new Date().getTime(),
        updatedAt: new Date().getTime(),
        createdBySupportWorkerId: risk?.createdBySupportWorkerId || user?.profile,
        supportedPersonId: customer?.id,
        relatedAssessment: risk?.relatedAssessment?.length && risk?.relatedAssessment?.length > 0 ? risk?.relatedAssessment : [type],
      },
      {},
    );
  }, [reset, risk, type, user?.profile, customer?.id]);

  const onSubmit = async (data: RiskInput) => {
    setOpenSnack(true);
    await saveRisk({
      variables: {
        input: {
          ...data,
          id: riskId,
          supportedPersonId: customer?.id,
        },
      },
    });
    navigate(-1);
  };

  const assessmentOptions = [
    { value: 'behaviour', label: 'Behaviour' },
    { value: 'communication', label: 'Communication' },
    { value: 'condition-specific', label: 'Condition Specific' },
    { value: 'coshh', label: 'COSHH' },
    { value: 'covid', label: 'Covid-19' },
    { value: 'dysphagia', label: 'Dysphagia' },
    { value: 'end-of-life', label: 'End of Life' },
    { value: 'environment-and-fire', label: 'Environment and Fire' },
    { value: 'medication', label: 'Medication' },
    { value: 'mental-capacity', label: 'Mental Capacity' },
    { value: 'moving-and-handling', label: 'Moving and Handling' },
    { value: 'seizures', label: 'Seizures' },
  ];

  if (loading) {
    return <Loading />;
  }

  return (
    <FormProvider {...methods}>
      <Message response={[mutationSaveRisk]} openSnack={openSnack} setOpenSnack={setOpenSnack} />
      <LocationBar section={customer ? `${customer?.firstName} ${customer?.lastName}` : 'Risks'} page={`New ${type} Risk`} Icon={EditNote} />
      <div className="my-10 px-4 md:px-[5%]">
        <button
          type="button"
          onClick={() => navigate(-1)}
          className="w-fit text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md flex items-center gap-2"
        >
          <ArrowBack />
          <div>Go back</div>
        </button>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            <div className="bg-white rounded-xl mt-6 space-y-4">
              <TextAreaFieldWithoutLabel name="risk" placeholder="Describe the risk..." />
              <RadioFieldWithSignature
                name="riskLevel"
                label="Risk Level"
                options={[
                  { value: 'Low', label: 'Low' },
                  { value: 'Medium', label: 'Medium' },
                  { value: 'High', label: 'High' },
                ]}
              />
              <MultiSelectField name="relatedAssessment" label="Related Assessments" options={assessmentOptions} />
            </div>
            <div className="flex justify-end gap-4">
              <button type="button" onClick={() => navigate(-1)} className="px-4 py-2 text-gray-700 bg-gray-100 rounded-lg">
                Cancel
              </button>
              <button type="submit" className="px-4 py-2 text-white bg-primary-700 rounded-lg">
                Save Risk
              </button>
            </div>
          </form>
        </FormProvider>
      </div>
    </FormProvider>
  );
};

export default RiskForm;
